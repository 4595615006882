import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CcParametersService } from 'src/app/_services/cc-parameters.service';

@Component({
  selector: 'app-steps-bar',
  templateUrl: './steps-bar.component.html',
  styleUrls: ['./steps-bar.component.scss']
})
export class StepsBarComponent implements OnInit {

  @Input() active: number = null;
  
  steps2 = ["Krok 1", "Krok 2", "Krok 3"]
  steps = [
    {
      id:0,
      name: "Krok 1",
      path: "/"
    },
    {
      id:1,
      name: "Krok 2",
      path: "parameters/1"
    },
    {
      id:2,
      name: "Krok 3",
      path: "pallets/1"
    },
  ]
    
  
  constructor(
    private ccParametersService:CcParametersService,
    private router: Router
    ) { }

  ngOnInit(): void {
    // console.log(this.route.snapshot.queryParams)
  }

  goTo(step){
    // console.log(step)
    if(this.active == step.id)
      return true;
    else if(this.active < step.id){
      return false;
    }  

    if(step.id == 0){
      this.router.navigate([step.path])
    }else if(step.id == 1){
      // if(Object.keys(this.route.snapshot.queryParams).length > 0){     
        // console.log(this.ccParametersService.paramValues)   
        this.router.navigate([step.path],{queryParams:this.ccParametersService.paramValues})
      // }else{
      //   this.router.navigate([step.path]);
      // }
    }
  }
}
