import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Subscription } from 'rxjs';
import { CcParametersService } from 'src/app/_services/cc-parameters.service';
import { CcProductsService } from 'src/app/_services/cc-products.service';
import { ComplexConstructionsService } from 'src/app/_services/complex-constructions.service';
import { PalletsService } from 'src/app/_services/pallets.service';
import { ResultsService } from 'src/app/_services/results.service';
import { SystemNameService } from 'src/app/_services/system-name.service';

@Component({
  selector: 'app-pallets',
  templateUrl: './pallets.component.html',
  styleUrls: ['./pallets.component.scss']
})
export class PalletsComponent implements OnInit, OnDestroy {

  id = null;
  sysNameSubscribe: Subscription;
  cc = null;
  user_id = 0;
  sysName: string;
  pallets: any = {
    total:{base:0,add:0,img:null},
    grouped: {}
  };
  no_sys: boolean;
  sys_image: string;
  params: any = {};
  backPath: string = null;
  brick: string;
  constructor(
    private ccService: ComplexConstructionsService,
    private ccProductsService:CcProductsService,
    private ccParametersService:CcParametersService,
    private route: ActivatedRoute,
    private router: Router,
    private systemNameService: SystemNameService,
    private resultsService: ResultsService,
    private palletsService: PalletsService
    ) { }

  ngOnInit(): void {
    this.scroll();
    const id = this.route.snapshot.paramMap.get('id');

    this.id=id;
    if(this.resultsService.result){
      this.backPath = '/offer/'+this.resultsService.result.css_id;
    }else {
      this.backPath = '/parameters/1';
    }

    this.cc = this.ccService.getCC(id);
    if (!this.cc) {
      console.warn('Nie ma takiego systemu');
      this.router.navigate(['/']);
    }
    this.sysName = this.systemNameService.sysName;
    this.brick = this.systemNameService.brickImg
    this.sysNameSubscribe = this.systemNameService.name$.subscribe(data =>  {
      this.sysName = data;
      this.brick = this.systemNameService.brickImg
    });

    if(!this.ccProductsService.initiated || !this.ccParametersService.initiated){
      if(Object.keys(this.route.snapshot.queryParams).length > 0){
        this.router.navigate(['/parameters/1'],{queryParams:this.route.snapshot.queryParams});
      }else{
        this.router.navigate(['/']);
      }
    }else{      
      this.pallets = this.palletsService.calculatePallets();
      
      this.params=this.ccParametersService.paramValues;
      this.ccParametersService.setGetHeader();
    }
    // this.params='?'+this.ccParametersService.getSearchHeader()
    // console.log(this.params)


    this.sysNameSubscribe = this.systemNameService.name$.subscribe(data =>  {
      this.sysName = data;
      this.sys_image=this.systemNameService.sys_image;
      this.no_sys=this.systemNameService.no_sys;
    });
    this.sys_image=this.systemNameService.sys_image;
    
  }

  scroll() {
    window.scrollTo({top:0,behavior: 'smooth'});
  }

  ngOnDestroy(){
    this.sysNameSubscribe.unsubscribe();
  }
}
