import { KeyValue } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CcParametersService } from 'src/app/_services/cc-parameters.service';
import { CcProductsService } from 'src/app/_services/cc-products.service';
import { SystemNameService } from 'src/app/_services/system-name.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-params',
  templateUrl: './params.component.html',
  styleUrls: ['./params.component.scss']
})
export class ParamsComponent implements OnInit {
  @Input() cc: any;
  @Input() result: any = null;
  groupedParameters = null;
  paramValues = {};
  user_id = 0;
  isUser = false;
  objectKeys = Object.keys;
  inputLimiters = {};
  disclaimers = {};

  constructor(
    private ccParametersService: CcParametersService,
    private userService: UserService,
    private ccProductsService: CcProductsService,
    private systemNameService: SystemNameService,
    private route: ActivatedRoute
    ) {
    }

  ngOnInit(): void {    
      const ccParametersPromise = this.ccParametersService.init(this.cc.id_complex_constructions);
      ccParametersPromise.then(() =>  {
        if (this.result && this.result.css_params) {
          // console.log(this.result.css_params)
          this.ccParametersService.paramValues = this.result.css_params;
        } else if(window.location.search){
          
          this.ccParametersService.setGetHeader();
        }
        
        this.inputLimiters = this.ccParametersService.inputLimiters;
        // this.groupedParameters=this.ccParametersService.parameters;
        // this.groupedParameters = this.ccParametersService.activeParams;

        this.paramValues = this.ccParametersService.paramValues;
        // console.log(this.paramValues)
        this.disclaimers = this.ccParametersService.disclaimers;

        this.ccParametersService.setAvailableParams();

        this.groupedParameters = this.ccParametersService.activeParams;
        this.ccParametersService.setGetHeader();
        // this.onChange();
        
        this.isUser = this.userService.isUserSignedIn();
        this.getUserId();
        this.systemNameService.refreshName();
      });
  }


  onChange() {
    this.ccParametersService.overrideMaxInputs();
    this.ccParametersService.setAvailableParams();
    this.groupedParameters = this.ccParametersService.activeParams;
    this.ccProductsService.refreshProductsList();
    this.systemNameService.refreshName();
    // console.log( this.groupedParameters)
    this.ccParametersService.setGetHeader();
  }

  valueAscOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return a.value.toString().localeCompare(b.value);
  }

  keyAscOrder = (a: KeyValue<string,string>, b: KeyValue<string,string>): number => {    
    return parseInt(a.key) > parseInt(b.key) ? (parseInt(b.key) < parseInt(a.key) ? 1 : 0): -1 ;
  }

  getUserId() {
    if (this.isUser) {
      this.user_id = this.userService.user.id;
    } else {
      this.user_id = 0;
    }
  }

}
