import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from './api.service';
import { CcParametersService } from './cc-parameters.service';

@Injectable({
  providedIn: 'root'
})
export class SystemNameService {
  
  private _pattern : string;
  private _params;
  private _prods : string;
  private _sys_image : string;
  private _nameSource: Subject<string> = new Subject();
  name$ = this._nameSource.asObservable();
  
  private _brickImg : string;
  brick_pattern: any;
  public get brickImg() : string {
    return this._brickImg;
  }
  public set brickImg(v : string) {
    this._brickImg = v;
  }
  
  
  public get sys_image() : string {
    return this._sys_image;
  }
  public set sys_image(v : string) {
    this._sys_image = v;
  }
  
  private _no_sys : boolean;
  public get no_sys() : boolean {
    return this._no_sys;
  }
  public set no_sys(v : boolean) {
    this._no_sys = v;
  }
  
  private _systemNamesArray : Array<JSON>;
  
  
  
  private _sysName : string;
  public get sysName() : string {
    return this._sysName;
  }
  public set sysName(v : string) {
    this._sysName = v;
  }
  
  
  constructor(
    private apiService: ApiService,
    private ccParametersService: CcParametersService
  ) { 
    // this.pattern = "$p1$-$p2$/$p3$";
    // this.params = {"p1":12,"p2":10,"p3":9}
  }

  init(id){
    return new Promise((resolve, reject) => {
      this.apiService.getSystemName(id).subscribe((data: any)=>{
        for( let pattern_index in data) {
          
          data[pattern_index].ccsnp_param_val = JSON.parse(data[pattern_index].ccsnp_param_val)
          data[pattern_index].ccsnp_prod_val = JSON.parse(data[pattern_index].ccsnp_prod_val)
          data[pattern_index].ccsnp_rule = JSON.parse(data[pattern_index].ccsnp_rule)
          // console.log(JSON.parse(pattern.ccsnp_rule))
        }
        this.systemNamesArray = data;
        // this.pattern = data.ccsnp_pattern
        // this.params = JSON.parse(data.ccsnp_param_val)
        // this.prods = JSON.parse(data.ccsnp_prod_val)
        // console.log(JSON.parse(data.ccsnp_rule))
        resolve(true)
      })
      
    })
    
  }

  setActiveNamePattern() {
    for( let pattern of this.systemNamesArray) {
      // console.log(pattern)
      if(this.checkPatternRule(pattern['ccsnp_rule'])){
        this.pattern = pattern['ccsnp_pattern']
        this.params = pattern['ccsnp_param_val']
        this.prods = pattern['ccsnp_prod_val']
        this.sys_image = pattern['ccsnp_sys_image'];
        this.brick_pattern = pattern['ccsnp_brick_img'];
        return true;
      }
    }
    return false;
  }

  checkPatternRule(rule) {
    let param_values = {};
    for( let p in rule.params) {      
      let param_val = this.ccParametersService.paramValues[rule.params[p]];
      param_values[p] = param_val;
      
    }
    let rule_pattern = rule.rule;
    for(let param in param_values) {
      rule_pattern = rule_pattern.replaceAll(param,param_values[param])
    }
    if(eval(rule_pattern)) {
      return true;
    } else {
      return false;
    }

  }

  refreshName(){
    if(this.setActiveNamePattern()){

    }else{
      console.warn('brak wzoru nazwy')
    }

    let name = this.pattern
    let brick = this.brick_pattern;
    for(let p in this.params) { 
      let param_val = this.ccParametersService.paramValues[this.params[p]];
      let replacement = this.ccParametersService.rawParams[this.params[p]].param_values[param_val];
      if(replacement != undefined){
        name = name.replace("$"+p+"$", replacement)
        brick = brick.replace("$"+p+"$", replacement)
        this.no_sys = false;
      }else{
        console.log('no val')
        this.no_sys = true;
        name = 'Brak pakietu dla podanych parametrów';
        brick = 'placeholder.png';
        // let index = name.indexOf("$"+p+"$");
        // if ( index > 0){
        //   name = name.substring(0, index - 1) + name.substring(index, name.length);
        // }
        // name = name.replace("$"+p+"$", '');
      }
    }
    this.sysName = name;
    this.brickImg = brick;
    this.nameSource.next(this.sysName);
  }

  get nameSource(): Subject<string> {
    return this._nameSource;
  }
  set nameSource(data: Subject<string>) {
    this._nameSource = data;
  }

  public get pattern() : string {
    return this._pattern;
  }
  public set pattern(v : string) {
    this._pattern = v;
  }
  
  public get params() {
    return this._params;
  }
  public set params(v) {
    this._params = v;
  }

  public get systemNamesArray() : Array<JSON> {
    return this._systemNamesArray;
  }
  public set systemNamesArray(v : Array<JSON>) {
    this._systemNamesArray = v;
  }
  
  public get prods() : string {
    return this._prods;
  }
  public set prods(v : string) {
    this._prods = v;
  }
}
