import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ComplexConstructionsService {

  private _CCs: any = null;

  constructor(
    private apiService: ApiService
    ) {

    this.apiService.getCC().subscribe((data: any) => {
      this._CCs = data;
      this.setCCsImagesURLs();
    });
  }

  init(){
    return new Promise((resolve, reject) => {
        this.apiService.getCC().subscribe((data: any) => {
          this._CCs = data;
          this.setCCsImagesURLs();
        resolve(true);
      });
    });
  }

  get CCs() {
    return this._CCs;
  }

  getCC($id) {
    for (let i = 0; i < this._CCs.length; i++) {
      if (this._CCs[i].id_complex_constructions == $id)
        return this._CCs[i];
    }
    return null;
  }

  setCCsImagesURLs() {
    for (let i = 0; i < this.CCs.length; i++) {
      this.CCs[i].img = this.apiService.imgURL + this.CCs[i].img;
    }
  }

}
