import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { KeyValue, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CcParametersService {

  private _parameters=null;
  private _injectParameters=null;
  private _parameterValues={};
  private _defaultParams={};
  private _paramRules={};
  paramAdvancedRules={};
  private _activeParams={};
  private _rawParamsObj={};
  
  private _initiated : boolean = false;
  public get initiated() : boolean {
    return this._initiated;
  }
  public set initiated(v : boolean) {
    this._initiated = v;
  }
  
  private _disclaimers : Object = {};
  public get disclaimers() : Object {
    return this._disclaimers;
  }
  public set disclaimers(v : Object) {
    this._disclaimers = v;
  }
  
  inputLimiters={};
  id;
  constructor(
    private location: Location,
    private apiService:ApiService,
    private route: ActivatedRoute
  ){
  }

  init(id){
    this.id = id;
    
    return new Promise((resolve, reject) => {
      this.apiService.getData(id).subscribe((data: any)=>{ 
        this.parameters=data;
        if(Object.keys(this.route.snapshot.queryParams).length > 0){
          console.log('search params')
          this.getGetHeader();
        }else{
          this.paramValues={};
        }
        let groups=Object.keys(data); 
        for(let group_id of groups){    
          for(let i=0;i<data[group_id]['params'].length;i++){
            if(data[group_id]['params'][i].param_input == 2){
              data[group_id]['params'][i].param_values = this.sortObjectByKeys(data[group_id]['params'][i].param_values)              
            }

            this._rawParamsObj[data[group_id]['params'][i]['id_complex_constructions_parameters']]=data[group_id]['params'][i]
            
            if(data[group_id]['params'][i].param_rules){
              data[group_id]['params'][i].param_rules=JSON.parse(data[group_id]['params'][i].param_rules)
              this.paramRules[data[group_id]['params'][i]['id_complex_constructions_parameters']]=data[group_id]['params'][i].param_rules;
            }
            if(data[group_id]['params'][i].param_adv_rules){
              data[group_id]['params'][i].param_adv_rules=JSON.parse(data[group_id]['params'][i].param_adv_rules)
              this.paramAdvancedRules[data[group_id]['params'][i]['id_complex_constructions_parameters']]=data[group_id]['params'][i].param_adv_rules;
            }
            // let acceptedValues=this.getParamValueByRules(data[group_id]['params'][i])
            // data[group_id]['params'][i].acceptedValues=acceptedValues;
            this.parameters=data;
            // this.getParamValueByRules(data[group_id]['params'][i]);
            if(Object.keys(this.route.snapshot.queryParams).length > 0){
            }else{
              let def=this.assignDefaultParam(id,data[group_id]['params'][i].id_complex_constructions_parameters);
              if(def!=null){
                if(data[group_id]['params'][i].param_input == 1){
                  if(def == 0){
                    def = false;
                  }else {
                    def = true;  
                  }
                }
                this.paramValues[data[group_id]['params'][i].id_complex_constructions_parameters]=def;
                
              }else
                this.paramValues[data[group_id]['params'][i].id_complex_constructions_parameters]=data[group_id]['params'][i].param_default!=null?data[group_id]['params'][i].param_default:this.setDefaultValue(data[group_id]['params'][i].param_values);
            } 
          }
        }
        this.setAvailableParams();
        
        this.injectParameters=this.getParamsByType(0);
        this.initiated = true;
        resolve(true)
      })
      
    })
    
  }

  setAvailableParams(){
    let activeParams=JSON.parse(JSON.stringify(this.parameters));
    let paramHierarchy = this.getParamsHierarchyByRules();
    let rules = {}
    // console.log(this.paramRules)
    // iteracja po parametrach z regulami w kolejnosci hierarchii
    for(let param_id of paramHierarchy.hierarchy){
      // pomija te niezalezne
      if(paramHierarchy.dependency[param_id].length>0){
        
        let tmpParameter = this._rawParamsObj[param_id];
        let parameter = this.getParamByIdFromListOfParams(activeParams[tmpParameter['param_group_id']]['params'], param_id);
        
        // iteracja po parametrach od ktorych jest zalezny badany parametr
        for(let higher_param_id of paramHierarchy.dependency[param_id]){
          let value = this.paramValues[higher_param_id];
          
          // jesli nadrzedny parametr jest nullem to nie sprawdzam dalej
          // bo warunki i tak nie beda spelnione
          if(value == null)
            continue;

          // parametry bool sa konwertowane na 1/0
          if(value===true)
            value=1;
          else if(value===false)
            value=0

          // jesli wsrod regul dla nadrzednego parametru nie ma reguly dla badanego parametru
          // ktora obowiazywalaby przy obecnej wartosci nadrzednego parametru to omijamy regule
          let cases = this.paramRules[higher_param_id][value];
          // console.warn(cases)
          // console.log(higher_param_id,value,param_id)
          if(cases!= undefined && cases[param_id] != undefined){

            // tworzona jest lista dostepnych wartosci dla badanego parametru
            if(rules[param_id]!=undefined){
              rules[param_id]=rules[param_id].filter(v => cases[param_id].includes(v))
            }else{
              rules[param_id]=cases[param_id];
            }
          }
        }
        
        // jesli wystepuja jakies reguly
        if(rules[param_id]!=undefined){  

          // stworz obiekt dostepnych wartosci {id:val, ...}
          let values = {};
          for(let val_id of rules[param_id]){
            values[val_id]=parameter['param_values'][val_id]
          }
          // jesli lista wartosci jest pusta to wartosc wybranego parametru jest null. Lista wartosci rowniez jest nullowana, 
          // bo po tym komponent wie ze parametr ma zostac ukryty
          if(Object.keys(values).length==0){
            values=null;     
            this.paramValues[param_id]=null;   

          // jesli nie jest pusta a parametr to bool to trzeba przeksztalcic 1/0 na true/false
          }else if(parameter['param_input']=="1"){
            let boolVals=[];
            if(values[0]!=undefined){
              boolVals.push(false);
            }
            if(values[1]!=undefined){
              boolVals.push(true);
            }
            if(boolVals.length==1){
              this.paramValues[parameter['id_complex_constructions_parameters']]=boolVals[0];
            }
            values=boolVals;

          // dla pozostalych typow ustawiana jest wartosc domyslna (jesli jest) lub pierwsza dostepna wartosc
          }else /*if(parameter['param_input']=="2")*/{
            if(this.paramValues[param_id] == null || rules[param_id].indexOf(this.paramValues[param_id])<0) {
              if(rules[param_id].indexOf(parameter['param_default'])<0) {
                this.paramValues[param_id] = rules[param_id][0];
              }else{
                this.paramValues[param_id] = parameter['param_default'];
              }
            }
          }
          // zaktualizowana lista dostepnych wartosci parametru jest przypisywana do parametru
          // poprzez referencje od razu nadpisuje sie to w glownym obiekcie roboczym activeParams
          parameter['param_values']=values;
        }else{
          // jesli do parametru nie zastosowano zadnych regul to dostaje swoja wartosc domyslna (o ile nie mial ustawionej innej)
          if(this.paramValues[param_id] == null) {            
            this.paramValues[param_id] = parameter['param_default'];            
          }
        }
      }
      
    }
    
    activeParams = this.setActiveParamsByAdvancedRules(activeParams)
    // console.log(activeParams)
    this.activeParams=activeParams;
    this.setDisclaimers();
    this.setGroupVisibility();
    // this.setDefaultValues();
  }

  setGroupVisibility() {
    let visibility = true;

    for(let group in this.activeParams){
      visibility = false;

      for(let param of this.activeParams[group].params){

        if(param.param_values) {
          visibility = true;
          break;
        }

      }
      this.activeParams[group].visibility = visibility;
    }

  }

  setDisclaimers(){
    for(let param in this.rawParams) {
      if(this.rawParams[param].param_disclaimer != null){
        const disclaimers = JSON.parse(this.rawParams[param].param_disclaimer)
        for(let disclaimer of disclaimers ) {
          let rule = disclaimer.rule;
          
          for (let p in disclaimer.params) {
            // let val = null;
            // if(this.rawParams[disclaimer.params[p]].param_input==3){
            //   val = this.paramValues[disclaimer.params[p]]
            // }else{
            //   val = this.rawParams[disclaimer.params[p]].param_values[this.paramValues[disclaimer.params[p]]];
            // }
            rule = rule.replaceAll(p,this.paramValues[disclaimer.params[p]])
          }
          
          if(eval(rule)) {
            this.disclaimers[param]=disclaimer.disc;
            break;
          }else{
            this.disclaimers[param] = null;
          }
          
        }
        
      }
      
    }
  }

  getParamByIdFromListOfParams(list, id){
    for(let param of list){
      if(param['id_complex_constructions_parameters']==id){
        return param
      }
    }
    return null
  }
  setAvailableParams2(){
    let activeRules=this.getActiveRules();
    let activeParams=JSON.parse(JSON.stringify(this.parameters));
    let paramHierarchy = this.getParamsHierarchyByRules();
    // let activeAdvancedRules = this.setAvailableAdvancedRules();
    for(let paramGroup in activeParams){
      
      // console.log(activeParams)
      for(let i=0;i<activeParams[paramGroup].params.length;i++){
        let param=activeParams[paramGroup].params[i];

        if(activeRules[param['id_complex_constructions_parameters']]!=undefined){
          let values={};
          for(let vals in param['param_values']){
            if(activeRules[param['id_complex_constructions_parameters']].indexOf(vals)>-1){
              values[vals]=param['param_values'][vals];
            }
          }          
          if(Object.keys(values).length==0){
            values=null;           
          }else if(param['param_input']=="1"){
            let boolVals=[];
            if(values[0]!=undefined){
              boolVals.push(false);
            }
            if(values[1]!=undefined){
              boolVals.push(true);
            }
            if(boolVals.length==1){
              this.paramValues[param['id_complex_constructions_parameters']]=boolVals[0];
            }
            values=boolVals;
          }else{
            if(values[this.paramValues[param['id_complex_constructions_parameters']]]==undefined){
              this.paramValues[param['id_complex_constructions_parameters']]=Object.keys(values)[0];
            }
          }
          
          param['param_values']=values;
          activeParams[paramGroup].params[i]=param;
        }
      }
      
    }
    activeParams = this.setActiveParamsByAdvancedRules(activeParams)
    // console.warn(activeParams)
    this.activeParams=activeParams;
    // this.setDefaultValues();
  }

  getParamValues(){
    let params = {};
    for(let group in this.parameters){
      if(this.parameters[group].params.length>0){
        for(let param in this.parameters[group].params){
          params[this.parameters[group].params[param].id_complex_constructions_parameters]={params:this.parameters[group].params[param].param_values,input:this.parameters[group].params[param].param_input}
        }
      }
    }
    return params;
  }

  setActiveParamsByAdvancedRules(activeParams){
    for(let paramGroup in activeParams){
      
      for(let i=0;i<activeParams[paramGroup].params.length;i++){
        let param=activeParams[paramGroup].params[i]
        // console.log(param)
        if(param.param_values!=null && this.paramAdvancedRules[param['id_complex_constructions_parameters']]!=undefined){
          let values={};
          // console.log(param)
          let params = this.getParamValues();
          let emptyValOccurrence = false;
          for(let rule in this.paramAdvancedRules[param['id_complex_constructions_parameters']].rules) {
            let ruleCondition = this.paramAdvancedRules[param['id_complex_constructions_parameters']].rules[rule];
            let emptyVal=false;
            if(param.param_values[rule]!=undefined){
              
              for(let value in this.paramAdvancedRules[param['id_complex_constructions_parameters']].params) {

                if(params[this.paramAdvancedRules[param['id_complex_constructions_parameters']].params[value]].input==3){
                  let inputVal=this.paramValues[this.paramAdvancedRules[param['id_complex_constructions_parameters']].params[value]]
                  // console.warn(inputVal)
                  // if(inputVal == null || inputVal == 'null'){
                  //   emptyVal = true;
                  //   break;
                  // }else{
                    ruleCondition=ruleCondition.replaceAll(value,inputVal)
                  // }
                }else{
                  ruleCondition=ruleCondition
                    .replaceAll(value,params[this.paramAdvancedRules[param['id_complex_constructions_parameters']].params[value]].params[this.paramValues[this.paramAdvancedRules[param['id_complex_constructions_parameters']].params[value]]])
                }
              }
              if(emptyVal){
                emptyValOccurrence = true;
                values[rule]=param['param_values'][rule];
              }else{
                let condition = eval(ruleCondition)
                if(condition)
                  values[rule]=param['param_values'][rule];
              }
              
            }
            
          }
          // if(!emptyValOccurrence){
          if(values[this.paramValues[param['id_complex_constructions_parameters']]]==undefined){
            this.paramValues[param['id_complex_constructions_parameters']]=Object.keys(values)[0];
          }
          param['param_values']=values;
          activeParams[paramGroup].params[i]=param;
        }
      }
    }
    
    return activeParams;
  }

  getParamsHierarchyByRules(){
    // console.warn(this.paramRules)
    let ruleHierarchyArray = [];
    let paramsDependency = {};

    // tworzenie listy parametrow do ktorych przypisana jest lista parametrow od ktorych dany parametr jest zalezny
    for(let rule in this.paramRules){
      if(paramsDependency[rule]==undefined){
        paramsDependency[rule] = [];
      }

      for(let val in this.paramRules[rule]){
        
        let dependentParams = this._paramRules[rule][val];
        for(let param in dependentParams){
          if(paramsDependency[param]==undefined){
            paramsDependency[param] = [];            
          }
          if(paramsDependency[param].indexOf(rule)<0){
            paramsDependency[param].push(rule)
          }

        }

      }
    }
    //lista parametrow
    let temporartParamsArray = []
    for(let param in paramsDependency){
      temporartParamsArray.push(param);
    }
    // ustawianie parametrow w hierarchii
    while(temporartParamsArray.length > ruleHierarchyArray.length) {
      let inserted = false;
      for(let param in paramsDependency){
        
        if(temporartParamsArray.length <= ruleHierarchyArray.length) {
          break;
        }
        if(paramsDependency[param].length ==0){
          if(ruleHierarchyArray.indexOf(param)<0){
            ruleHierarchyArray.push(param);
            inserted = true;
          }
        }else{
          let ready = true;
          //sprawdza czy parametry z listy zaleznosci sa juz w tabeli hierarchii
          for(let i=0; i<paramsDependency[param].length;i++){
            if(ruleHierarchyArray.indexOf(paramsDependency[param][i])<0){
              ready = false;
              break;
            }
          }
          if(ready){
            if(ruleHierarchyArray.indexOf(param)<0){
              ruleHierarchyArray.push(param);
              inserted = true;
            }
          }
        }
        
      }
      if(inserted == false){
        console.error('pusty obieg - mozliwe wystapienie wspolzaleznosci parametrow')
        console.error(temporartParamsArray)
        console.error(ruleHierarchyArray)
        break;
      }
    }

    // console.log(ruleHierarchyArray)
    // console.log(paramsDependency)

    return {
      hierarchy: ruleHierarchyArray,
      dependency: paramsDependency
    }
  }

  getActiveRules(){
    let activeRules={};
    for(let rule in this.paramRules){
      let value=this.paramValues[rule]
      if(this.paramValues[rule]===true)
        value=1;
      else if(this.paramValues[rule]===false)
        value=0

      if(this.paramRules[rule][value]!=undefined){
        // console.log(rule)
        // console.log(value)
        // console.log(this.paramRules[rule][value])
        for(let i=0;i<this.paramRules[rule][value].length;i++){
          for(let param_id in this.paramRules[rule][value][i]){
            if(activeRules[param_id]!=undefined){
              activeRules[param_id]=activeRules[param_id].filter(v => this.paramRules[rule][value][i][param_id].includes(v))
            }else{
              activeRules[param_id]=this.paramRules[rule][value][i][param_id];
            }
          }
          // activeRules=Object.assign(activeRules,this.paramRules[rule][value][i]);
        }
      }else{
        for(let inputID in this.paramRules[rule]){
          if(inputID.indexOf('v')>-1){
            for(let i=0;i<this.paramRules[rule][inputID].length;i++){
              for(let param_id in this.paramRules[rule][inputID][i]){
                let sourceID=this.paramRules[rule][inputID][i][param_id].substring(1);
                let limit=this._parameterValues[sourceID]
                this.inputLimiters[param_id]=limit
              }
            }
          }
        }
        // inputLimiters
      }
    }
    // console.log(activeRules)
    return activeRules;
  }
  
  setGetHeader(){
    const query = this.getSearchHeader();
    const path=this.location.path().replace(window.location.search,'')
    this.location.go(path + '?'+ query)
  }

  getGetHeader(){
    let vals = {};
    for(let param in this.route.snapshot.queryParams){
      let val = this.route.snapshot.queryParams[param]
      if(val==='true'){
        val = true;
      }else if(val === 'false'){
        val = false
      }else if(val === 'null'){
        val = null
      }
      vals[param] = val;
    }
    this.paramValues=JSON.parse(JSON.stringify(vals))
  }

  getSearchHeader(){
    let search_params = new URLSearchParams(); 
    for(let param in this.paramValues) {
      // if(this.paramValues[param]!=null) {
      let p;
        if(this.paramValues[param]==true)
          p = 1;
        else if(this.paramValues[param]==false)
          p = 0;
        else if(this.paramValues[param]==null)
          continue;
        else
          p = this.paramValues[param]
          
        search_params.append(param, p);
      // }
    }
    const query_string = search_params.toString();    
    return query_string
  }



  overrideMaxInputs(){
    for(let paramVal in this.paramValues){
      if(this.inputLimiters[paramVal]!=undefined){
        if(this.paramValues[paramVal]>=this.inputLimiters[paramVal])
          this.paramValues[paramVal]=this.inputLimiters[paramVal]
      }
    }
  }

  setDefaultValue(params){
      // if(typeof params === "object"){
      if(!Array.isArray(params)){
        let keys=Object.keys(params);
        return keys[0];
      }else{
        return params[0];
      }
  }

  setDefaultParams(defaults){
    // let defaultParams={};
    this._defaultParams=defaults;
  }

  assignDefaultParam(cc_id,param_id){
    let paramDefaultsArr=this.defaultParams[cc_id]
    let paramDefaultsSet=null;
    if(!paramDefaultsArr)
      return null;
      
    if(paramDefaultsArr.length==0)
      return null;    
    else if(paramDefaultsArr.length>1){
      paramDefaultsSet=paramDefaultsArr[0][0];
    }else{
      paramDefaultsSet=paramDefaultsArr[0][0];
    }

    if(paramDefaultsSet[param_id]!=undefined)
      return paramDefaultsSet[param_id];
    else
      return null;

  }

  get defaultParams(){
    return this._defaultParams;
  }
  /**
   * 0 parametr niewidoczny uzupelniany przez wartosc z produktu
   * 1 parametr ustawiany przez kazdego
   * 2 parametr ustawiany tylko przez sprzedawce 
   * @param {*} param_type int 0,1,2
   * @memberof AppComponent
   */
  getParamsByType(param_type){
    let result=[];
    let groups=Object.keys(this.parameters);
    for(let group of groups){
      for(let i=0;i<this.parameters[group]['params'].length;i++){
        if(this.parameters[group]['params'][i].param_type==param_type){
          result.push(this.parameters[group]['params'][i]);
        }
      }
    }
    return result;
  }

  setParamsValues(product){
    let rules=[];
    rules=product.product_rules;
    for(let j=0;j<rules.length;j++){
      if(rules[j].cc_rules_group==0)
        this.paramValues[rules[j].cc_param_id]=rules[j].cc_rules_value
    }
  }

  clearInputParams(){
    if(this.injectParameters)
      for(let j=0;j<this.injectParameters.length;j++){
        this.paramValues[this.injectParameters[j].id_complex_constructions_parameters]=null;
      }
  }
  sortObjectByKeys(o) {
    return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
  }
  
  set parameters(data){
    this._parameters=data;
  }

  get parameters(){
    return this._parameters;
  }

  set paramRules(data){
    this._paramRules=data;
  }

  get paramRules(){
    return this._paramRules;
  }
  set activeParams(data){
    this._activeParams=data;
  }

  get activeParams(){
    return this._activeParams;
  }
  // setParameter(id,data){
  //   this._parameters[id]=data;
  // }

  // getParameter(id){
  //   return this._parameters[id];
  // }

  set injectParameters(data){
    this._injectParameters=data;
  }

  get injectParameters(){
    return this._injectParameters;
  }

  get paramValues(){
    return this._parameterValues;
  }

  set paramValues(data){
    this._parameterValues=data;
  }

  get rawParams(){
    return this._rawParamsObj;
  }

  set rawParams(data){
    this._rawParamsObj=data;
  }

}
