import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/_services/user.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-head-bar',
  templateUrl: './head-bar.component.html',
  styleUrls: ['./head-bar.component.scss']
})
export class HeadBarComponent implements OnInit, OnDestroy {

  userId = null;
  user_name = null;
  subscription: Subscription;

  constructor(
    private modalService: NgbModal,
    // private modalService: ModalService,
    private userService: UserService,
    private router: Router
    ) {
      this.subscription = this.userService.user$.subscribe(data => {
        this.userId = data.id;
        this.user_name  = data.name;
      });
     }

  ngOnInit() {
    this.userId = this.userService.user.id;
    this.user_name = this.userService.user.name;
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  userModal(content) {
    // this.modalService.open
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {
    });

  }

  singOut() {
    this.userService.signOut();
    this.router.navigate(['/']);
  }
}
