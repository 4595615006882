import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';
import { Subscription } from 'rxjs';
import { ComplexConstructionsService } from 'src/app/_services/complex-constructions.service';
import { ResultsService } from 'src/app/_services/results.service';
// import { CcParametersService } from 'src/app/_services/cc-parameters.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CcProductsService } from 'src/app/_services/cc-products.service';
import { SystemNameService } from 'src/app/_services/system-name.service';
// import { DiscountGroupsService } from 'src/app/_services/discount-groups.service';
// import { CcParametersService } from 'src/app/_services/cc-parameters.service';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss']
})
export class ParametersComponent implements OnInit {

  groupedParameters = null;
  paramValues = {};

  layers = null;
  availableProducts = {};
  // solutionResult={};
  totalPrice = null;

  isUser = false;
  subscription: Subscription;
  sysNameSubscribe: Subscription;
  id = null;

  cc = null;
  user_id = 0;
  sysName: string;
  no_sys: boolean;
  sys_image: string;
  brick: string;
  constructor(
    // private ccParametersService:CcParametersService,
    // private ccProductsService: CcProductsService,
    // private discountGroupsService: DiscountGroupsService,
    private ccService: ComplexConstructionsService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private modalService: NgbModal,
    private systemNameService: SystemNameService,
    private resultsService: ResultsService
  ) {
    this.isUser = this.userService.isUserSignedIn();
    this.getUserId();

    this.sysNameSubscribe = this.systemNameService.name$.subscribe(data =>  {
      this.sysName = data;
      this.sys_image=this.systemNameService.sys_image;
      this.brick = this.systemNameService.brickImg
      this.no_sys=this.systemNameService.no_sys;
    });

    this.subscription = this.userService.user$.subscribe(data => {
      this.isUser = this.userService.isUserSignedIn();
      // this.discountGroupsService.initDiscountGroupsObj();
      // this.ccProductsService.setDiscounts(this.discountGroupsService.discounts);
      this.getUserId();
    });
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.id=id;
    this.resultsService.clearResults();

    // console.log(id)
    // let systemNamePromise = this.systemNameService.init(id);
    // systemNamePromise.then(() => {
    //   console.log('test')
    // })

    this.sys_image=this.systemNameService.sys_image;
    this.brick = this.systemNameService.brickImg
    
    this.cc = this.ccService.getCC(id);
    if (!this.cc) {
      console.warn('Nie ma takiego systemu');
      this.router.navigate(['/']);
    }

  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
    this.sysNameSubscribe.unsubscribe();
  }

  getUserId() {
    if (this.isUser) {
      this.user_id = this.userService.user.id;
    } else {
      this.user_id = 0;
    }
  }

  clientDataModal(content) {
    // this.modalService.open
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {
    });

  }
}
