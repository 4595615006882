import { Component, OnInit, OnDestroy, AfterViewInit, AfterViewChecked } from '@angular/core';
import { PathsService } from 'src/app/_services/paths.service';
import { ComplexConstructionsService } from 'src/app/_services/complex-constructions.service';
import { Router } from '@angular/router';
import { CalcParametersService } from 'src/app/_services/calc-parameters.service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-select-path',
  templateUrl: './select-path.component.html',
  styleUrls: ['./select-path.component.scss']
})
export class SelectPathComponent implements OnInit, OnDestroy, AfterViewChecked {

  selectedParameters = {};
  formatedPath = [];
  CCs = [];
  filteredCCs = {};
  nextButton = false;
  currentPath = [];
  scrollToButton = false;
  isUser = false;
  subscription: Subscription;
  constructor(
    private pathsService: PathsService,
    private userService: UserService,
    private calcParametersService: CalcParametersService,
    private ccService: ComplexConstructionsService,
    private router: Router
  ) { 
    const calcParametersPromise = this.calcParametersService.init();
    calcParametersPromise.then(() => {
      this.currentPath = this.calcParametersService.currentPath;
      this.pathsService.filterAllCCs(true);
      this.filteredCCs = this.pathsService.filteredCCs;
      this.selectedParameters =  {};
      console.log('param promise')
    });
    
    this.subscription = this.userService.user$.subscribe(data => {
      this.isUser = this.userService.isUserSignedIn();
    });
  }

  ngOnInit(): void {
    // this.CCs = this.ccService.CCs;

    this.nextButton = false;
    this.isUser = this.userService.isUserSignedIn();
    this.scrollToButton = false;
  }

  ngAfterViewChecked(){
    if(this.scrollToButton) {
      this.scroll();
      this.scrollToButton = false;
    }
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }


  proceedOnPath(paramId, stepId, nextParamId) {
    if (this.selectedParameters[paramId] != undefined && this.selectedParameters[paramId] != stepId) {
      this.calcParametersService.goBackOnPath(paramId);
      this.calcParametersService.addToPath(paramId);
    }
    this.calcParametersService.selectParam(paramId, stepId);
    if (nextParamId != null) {
      const step = this.calcParametersService.addToPath(nextParamId);
      this.currentPath = this.calcParametersService.currentPath;
      // this.filteredCCs=
      this.nextButton = false;
      this.autoselectForOneOption(step);
    } else {
      this.nextButton = true;
      this.scrollToButton = true;
    }
    this.filteredCCs = this.pathsService.checkPath(this.calcParametersService.selectedParameters);

    this.selectedParameters = this.calcParametersService.selectedParameters;

    return this.nextButton;
  }
  scroll() {
    const el = document.getElementById('next');
    window.scrollTo({top:el.offsetTop - window.innerHeight + 80,behavior: 'smooth'});
  }

  autoselectForOneOption(step){
    if(step.steps.length == 1){
      this.proceedOnPath(step.cccp_id,step.steps[0].ccsp_id,step.steps[0].next_cccp_id)
    }
  }

  proceedToCalc(id) {
    this.router.navigate(['/parameters/' + id]);
  }


  selectSolution(ccId) {
    this.proceedToCalc(ccId);
  }

}
