import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CcProductsService } from 'src/app/_services/cc-products.service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-discount-tab',
  templateUrl: './discount-tab.component.html',
  styleUrls: ['./discount-tab.component.scss']
})
export class DiscountTabComponent implements OnInit, OnDestroy {
  private discounts = [];
  @Input() result: any = null;

  isUser = false;
  user_type: number = null;
  subscription: Subscription;

  constructor(
    private ccProductsService: CcProductsService,
    private userService: UserService,
  ) {
    this.subscription = this.userService.user$.subscribe(data => {
      this.isUser = this.userService.isUserSignedIn();
      
      this.ccProductsService.setDiscounts(this.discounts);
      if (this.isUser) {
        this.user_type = this.userService.getUserType();
      }
    });
  }

  ngOnInit(): void {
    console.warn('disc tab init')
    // this.initDiscountGroupsObj(this.discountGroupsService.discountGroups);
    // this.discounts = this.discountGroupsService.initDiscountGroupsObj()
    if (this.result) {
      const savedDiscounts = JSON.parse(this.result.css_discount);
      this.matchDiscounts(savedDiscounts);
    }
    
    this.isUser = this.userService.isUserSignedIn();
    if (!this.isUser) {
    } else {
      this.user_type = this.userService.getUserType();
    }
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  initDiscountGroupsObj(discountGroupsData) {
    const discounts = [];
    for (const groupData of discountGroupsData) {
      const group = {
        id: groupData.id_products_groups,
        name: groupData.name,
        discount: 0,
        margin: 0,
      };
      discounts.push(group);
    }
    this.discounts = discounts;
  }

  matchDiscounts(savedDiscounts) {
    for (const discount of savedDiscounts) {
      this.setDiscountMarginById(discount);
    }
  }

  setDiscountMarginById(savedDiscount) {
    const discount = this.getDiscountGroupById(savedDiscount.id);
    if (discount) {
      discount.discount = savedDiscount.discount;
      discount.margin = savedDiscount.margin;
    }
  }

  getDiscountGroupById(id) {
    const results = this.discounts.find( obj => {
      return obj.id == id;
    });
    return results;
  }
  submitDiscounts() {
    this.ccProductsService.setDiscounts(this.discounts);
  }
}
