import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ParametersComponent } from '../components/parameters/parameters.component';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // private _url = '//test.hybrid.pl/termat/bim/index_api.php?co=get_data';
  // private _imgURL = '//test.hybrid.pl/termat/bim/files/uploaded/cc/';
  // private _filesURL = '//test.hybrid.pl/termat/bim/files/';

  private _url = '//kalkulator.termat.pl/bim/index_api.php?co=get_data';
  private _imgURL = '//kalkulator.termat.pl/bim/files/uploaded/cc/';
  private _filesURL = '//kalkulator.termat.pl/bim/files/';
  
  // private _url = '//localhost/termat/bim/index_api.php?co=get_data';
  // private _imgURL = '//localhost/termat/bim/files/uploaded/cc/';
  // private _filesURL = '//localhost/termat/bim/files/';

  

  constructor(private _http: HttpClient) {
  }

  get url() {
      return location.protocol + this._url;
  }

 get imgURL() {
   return location.protocol + this._imgURL;
 }

 get filesURL() {
  return location.protocol + this._filesURL;
 }

  getData(id): Observable<any> {
    return this._http.get<any>(this.url + '&what=get_cc_properties&id=' + id);
  }
  getCCProducts(id): Observable<any> {
    return this._http.get<any>(this.url + '&what=get_cc_products&id=' + id);
  }
  getDiscountGroups(): Observable<any> {
    return this._http.get<any>(this.url + '&what=get_discount_groups');
  }
  getCalcParams(): Observable<any> {
    return this._http.get<any>(this.url + '&what=get_calc_params');
  }

  getCCSelectPath(): Observable<any> {
    return this._http.get<any>(this.url + '&what=get_select_path');
  }
  getCCSelectedPath(): Observable<any> {
    return this._http.get<any>(this.url + '&what=get_selected_path');
  }

  getCCRules(): Observable<any> {
    return this._http.get<any>(this.url + '&what=get_calc_cc_rules');
  }

  getCC(): Observable<any> {
    return this._http.get<any>(this.url + '&what=get_complex_constructions');
  }
  getSystemName(id) {
    let formData: any = new FormData();
    formData.append('id', id);

    return this._http.post<any>(this.url + '&what=get_sys_name', formData);
  }
  
  getPalletRules() {
    // let formData: any = new FormData();
    // formData.append('id', id);

    return this._http.get<any>(this.url + '&what=get_pallets_rules');
  }

  getUser(login, psswd): Observable<any> {
    let formData: any = new FormData();
    formData.append('login', login);
    formData.append('password', psswd);
    
    return this._http.post<any>(this.url + '&what=get_user', formData);

  }
  getDiscountCode(code) {
    let formData: any = new FormData();
    formData.append('code', code);

    return this._http.post<any>(this.url + '&what=get_code', formData);
  }

  saveResults(user_id, cc_id, params, results, discount, email, response_for, response_counter): Observable<any> {
    let formData: any = new FormData();
    formData.append('user_id', user_id);
    formData.append('cc_id', cc_id);
    formData.append('paramValues', params);
    formData.append('results', results);
    formData.append('discount', discount);
    formData.append('email', email);
    formData.append('response_for', response_for);
    formData.append('response_counter', response_counter);
    return this._http.post<any>(this.url + '&what=save_result',formData);
    
  }

  sendResults(user_id, cc_id, paramValues, activeParams, results, pallets, sysData, discount, email, contact, response_for, r_id, response_counter): Observable<any>{
    // return null;
    let formData: any = new FormData();
    formData.append('user_id', user_id);
    formData.append('cc_id', cc_id);
    formData.append('paramValues', paramValues);
    formData.append('activeParams', activeParams);
    formData.append('results', results);
    formData.append('pallets', pallets);
    formData.append('sysData', sysData);
    formData.append('discount', discount);
    formData.append('email', email);
    formData.append('contact', contact);
    formData.append('response_for', response_for);
    formData.append('r_id', r_id);
    formData.append('response_counter', response_counter);
    return this._http.post<any>(this.url + '&what=send_result',formData)
    
  }
  getResult(id): Observable<any> {
    return this._http.get<any>(this.url + '&what=get_result&r_id=' + id);
  }

  generateReport(id): Observable<any> {
    return this._http.get<any>(this.url + '&what=generate_report&r_id=' + id);
  }

  getResultsForUser(user_id): Observable<any> {
    var formData: any = new FormData();
    formData.append('user_id', user_id);
    return this._http.post<any>(this.url + '&what=get_results',formData)
  }

  deleteResult(r_id): Observable<any> {
    var formData: any = new FormData();
    formData.append('r_id', r_id);
    return this._http.post<any>(this.url + '&what=delete_result',formData)
  }

  downloadXML(params:any, contentType: string) {
    return this._http
      .get( this.url + '&what=create_xml&offer=' + params.offer_id, { headers: new HttpHeaders().append('Content-Type', contentType), responseType: 'arraybuffer'});      
  }

  // private downloadXML(params:any,contentType: string) {
  //   return this._http
  //     .get(this.url+'&what=create_xml&offer=' + params.offer_id, { headers: new HttpHeaders().append('Content-Type', contentType), responseType: 'blob', observe: 'body' })
  // }

  createXML(data) {
    // let contentType='text/xml';
    let contentType ='application/zip';
    let year=new Date().getFullYear();
    // let filename='wycena_'+data+'_'+year+'.xml'
    let filename='wycena_'+data+'_'+year+'.zip';
    return this.downloadXML({offer_id:data},contentType).subscribe(
      res => {
        console.log(res)
        // var url = window.URL.createObjectURL(res);
        var binaryData = [];
        binaryData.push(res);
        var url = window.URL.createObjectURL(new Blob(binaryData, {type: "application/zip"}))
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }, error => {
        console.log('download error:', JSON.stringify(error));
      }, () => {
        console.log('Completed file download.')
      });

  }

  printPdf(){
    var newWin = window.frames[0];
      newWin.document.write('<body onload="window.print()"><iframe style="position:fixed; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;" src="https://www.mantisbt.org"></body>');
      newWin.document.close();
  }
}
