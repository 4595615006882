import { Component, OnInit, Input } from '@angular/core';
import { ResultsService } from 'src/app/_services/results.service';
import { UserService } from 'src/app/_services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-client-modal',
  templateUrl: './client-modal.component.html',
  styleUrls: ['./client-modal.component.scss']
})
export class ClientModalComponent implements OnInit {
  // @Input() cc: any;
  @Input() mode: string;

  clientPhone = '';
  userContactData = {
    phone:'',
    name:'',
    postcode:'',
    address:'',
    city:'',  
    dealer_code:''
  }
  userEmails = new FormGroup({
    primaryEmail: new FormControl('',[
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
      ]),      
    }); 

  mailValid = false;
  loader = false;
  success = false;
  isUser = false;
  subscription: Subscription;
  constructor(
    private userService: UserService,
    private modalService: NgbModal,
    private resultsService: ResultsService
  ) { }

  ngOnInit(): void {
    this.userEmails.value['primaryEmail'] = this.resultsService.result ? this.resultsService.result.css_created_for : '';
    this.clientPhone = '';
    this.loader = false;
    this.success = false;
    
    this.subscription = this.userService.user$.subscribe(data => {
      this.isUser = this.userService.isUserSignedIn();
    });
    this.isUser = this.userService.isUserSignedIn();
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  closeModal() {
    this.modalService.dismissAll();
  }
  sendResult() {
    if(this.userEmails.get('primaryEmail').invalid){
      this.userEmails.get('primaryEmail').markAsTouched();
      return false;
    }
    const email = this.userEmails.value['primaryEmail'].length > 0 ? this.userEmails.value['primaryEmail'] : null;
    const phone = this.clientPhone.length > 0 ? this.clientPhone : null;
    const resultPromise = this.resultsService.sendResults(1, this.userService.getUserId(), email,this.userContactData);
    // this.setLoader();
    this.loader = false;
    this.success = true;
    resultPromise.then(() =>  {
      this.loader = false;
      this.success = true;
      console.log('send')
      // this.modalService.dismissAll();
    });
  }
  

  saveResult() {
    const email = this.userEmails.value['primaryEmail'].length > 0 ? this.userEmails.value['primaryEmail'] : null;
    const resultPromise = this.resultsService.saveResults(1, this.userService.getUserId(), email);
    this.setLoader();
    resultPromise.then(() =>  {
      // this.modalService.dismissAll();
    });
  }

  setLoader() {
    this.loader = true;
  }
}
