import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/_services/user.service';
import { Router } from '@angular/router';
import { ResultsService } from 'src/app/_services/results.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit,OnDestroy {

  isUser = false;
  subscription: Subscription;
  user_type: number = null;
  resultsList = [];

  private _max_elem_per_page = 10;
  sort = {type: 'date', direction: 'desc'};

  pages = 1;
  activePage = 1;
  filter = {customer: true, seller: true, termat: true};

  deleteModalData={}

  constructor(
    private userService: UserService,
    private router: Router,
    private modalService: NgbModal,
    private resultsService: ResultsService
  ) {
    this.isUser = this.userService.isUserSignedIn();
  }

  ngOnInit(): void {
    this.deleteModalData={}
  
    this.subscription = this.userService.user$.subscribe(data => {
      this.isUser = this.userService.isUserSignedIn();
      if (!this.isUser) {
        this.router.navigate(['/']);
      } else {
        this.user_type = this.userService.getUserType();
      }

    });

    if (!this.isUser) {
      this.router.navigate(['/']);
    } else {
      this.user_type = this.userService.getUserType();
    }

    let resListPromise = null;
    if (this.userService.getUserType() == 2) {
      resListPromise = this.resultsService.getResultsList(null);
    } else {
      resListPromise = this.resultsService.getResultsList(this.userService.getUserId());
    }
    resListPromise.then(() =>  {
      this.resultsList = this.resultsService.resultsList;
      // console.log(this.resultsList)
      this.pages = Math.ceil(this.resultsList.length / this._max_elem_per_page);
    });
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  deleteModal(content,res) {
    // this.modalService.open
    this.deleteModalData['id']=res.css_id;
    this.deleteModalData['index']=res.css_report_index;
  
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      // console.log('test')
    }, (reason) => {
      // console.log('test2')
    });

  }

  changeSort(type) {
    if (this.sort.type == type) {
      this.sort.direction = this.swapSortDirection();
    }
    this.sort.type = type;
    const sortType = this.getSortTag(this.sort.type);

    if (sortType == null) {
      return false;
    }
    if (this.sort.direction == 'asc') {
      this.sortListASC(sortType);
    } else {
      this.sortListDESC(sortType);
    }
  }

  swapSortDirection() {
    if (this.sort.direction == 'asc') {
      return 'desc';
    } else {
      return 'asc';
    }
  }

  getSortTag(type) {
    switch (type) {
      case 'date':
        return 'css_date';
      case 'id':
        return 'css_id';
      case 'author':
        return 'generated_by';
      case 'client':
        return 'css_created_for';
      default:
        return null;
    }
  }

  sortListASC(type) {
    const res = this.resultsList.sort((a, b) => (a[type] > b[type]) ? 1 : ((b[type] > a[type]) ? -1 : 0));
  }

  sortListDESC(type) {
    const res = this.resultsList.sort((b, a) => (a[type] > b[type]) ? 1 : ((b[type] > a[type]) ? -1 : 0));
  }


  filterList() {
    this.resultsList = this.resultsService.resultsList.filter((res) => {

      if (this.filter.termat && res.user_type == 2
        || this.filter.seller && res.user_type == 1
        || this.filter.customer && res.user_type == 0  ) {
        return res;
      }
    });
    this.pages = Math.ceil(this.resultsList.length / this._max_elem_per_page);
  }

  printPdf(rId, report) {
    this.resultsService.printPdf(rId, report);
  }

  setActivePage(i) {
    i = parseInt(i);
    if (this.pages < i) {
      i = this.pages;
    } else if (i < 1) {
      i = 1;
    }
    this.activePage = i;
  }
  resultPage(activePage) {
    const begin = this._max_elem_per_page * (activePage - 1);
    let end = begin + this._max_elem_per_page;
    if (end >= this.resultsList.length) {
      end = this.resultsList.length;
    }
    return this.resultsList.slice(begin, end);
  }
  pagesBoxes() {
    const maxButtons = 5;
    let buttons = [];

    if (this.pages <= maxButtons) {
      return Array(this.pages).fill(0).map((x, i) => i + 1);
    } else {
      if (this.activePage < maxButtons) {
        buttons = Array(maxButtons).fill(0).map((x, i) => i + 1);
        buttons.push('...');
        buttons.push(this.pages);
      } else if (this.activePage > this.pages - maxButtons) {
        buttons.push(1);
        buttons.push('...');
        const arr = Array(maxButtons).fill(0).map((x, i) => this.pages - maxButtons + i + 1);
        buttons = buttons.concat(arr);
      } else {
        buttons.push(1);
        buttons.push('...');
        buttons.push(this.activePage - 1);
        buttons.push(this.activePage);
        buttons.push(this.activePage + 1);
        buttons.push('...');
        buttons.push(this.pages);
      }
      return buttons;
    }

  }

  quickXML(id) {
    this.resultsService.createXML(id);

  }


  deleteOffer(rId) {
    const resPromise = this.resultsService.deleteResult(rId);
    resPromise.then(() =>  {
      this.resultsList = this.resultsService.resultsList;
      this.pagesBoxes();
    });
    this.closeModal();
  }
}
