import { Injectable, ɵɵresolveBody } from '@angular/core';
import { ApiService } from './api.service';
import { CcParametersService } from './cc-parameters.service';
import { CcProductsService } from './cc-products.service';
import { PalletsService } from './pallets.service';
import { SystemNameService } from './system-name.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ResultsService {

  private _result: any;
  private _currentResultId: number=null;
  private _resultsList: any=null;
  private _responseCounter = 0;
  constructor(
    private apiService: ApiService,
    private ccParametersService: CcParametersService,
    private userService: UserService,
    private ccProductsService: CcProductsService,
    private palletsService: PalletsService,
    private sysName: SystemNameService
    ) { }

  saveResults(cc_id,uId,email?) {
    if(!this.userService.user.id) {
      uId = 0;
    }
    let discount=this.ccProductsService.discount?this.ccProductsService.discount:0;
    // const discounts = JSON.stringify(this.ccProductsService.discountGroups);
    const params = JSON.stringify(this.ccParametersService.paramValues);
    const results = JSON.stringify(this.ccProductsService.solutionResult);
    
    const response_id = this.getResponseId();
    if (response_id !== null) {
      this.responseCounter++;
    }
    return new Promise((resolve, reject) => {
      this.apiService.saveResults(
        uId,
        cc_id,
        params,
        results,
        discount,
        email,
        response_id,
        this.responseCounter
        ).subscribe((data: any) => { 
          this.currentResultId = data;
          resolve(true);
        });
    });
  }

  sendResults(cc_id, uId, email?, userContact?) {
    if (!this.userService.user.id) {
      uId = 0;
    }

    const sysData = {
      name: this.sysName.sysName,
      sys_image: this.sysName.sys_image,
      brick_img: this.sysName.brickImg
    }
    let discount=this.ccProductsService.discount?this.ccProductsService.discount:0;
    // const discounts = JSON.stringify(this.ccProductsService.discountGroups);
    const paramValues = JSON.stringify(this.ccParametersService.paramValues);
    const activeParams = JSON.stringify(this.ccParametersService.activeParams);
    const results = JSON.stringify(this.ccProductsService.solutionResult);
    const sysDataStr = JSON.stringify(sysData);
    const response_id = this.getResponseId();
    const pallets = JSON.stringify(this.palletsService.pallets);
    const contact = JSON.stringify(userContact);
    console.log(userContact)
    if (response_id !== null) {
      this.responseCounter++;
    }
    return new Promise((resolve, reject) => {


      this.apiService.sendResults(
        uId,
        cc_id,
        paramValues,
        activeParams,
        results,
        pallets,
        sysDataStr,
        discount,
        email,
        contact,
        response_id,
        this.currentResultId,
        this.responseCounter
        ).subscribe((data: any) => { 
          this.currentResultId = data;
          resolve(true);
        });
    });
  }

  getResponseId(): number {
    let response_id = null;
    if (this.result) {
      if (this.result.css_response_for !== null) {
        response_id = this.result.css_response_for;
      } else {
        response_id = this.result.css_id;
      }
    }
    return response_id;
  }

  getResults(rId, uId) {
    return new Promise((resolve, reject) => {
      this.apiService.getResult(rId).subscribe((data: any) => { 
        data.css_params = JSON.parse(data.css_params);
        data.css_results = JSON.parse(data.css_results);
        this.result = data;
        this.currentResultId = this.result.css_id;
        this.responseCounter = parseInt(this.result.css_response_counter);
        resolve(true);
      });
    });
    
  }

  getResultsList(uId) {
    return new Promise((resolve, reject) => {
      this.apiService.getResultsForUser(uId).subscribe((data: any) => { 
        this.resultsList = data;
        resolve(true);
      });
    });    
  }
  clearResults() {
    this.result = null;
    this.currentResultId = null;
    this.responseCounter = 0;
  }

  printPdf(rId,report) {
    if (!report) {
      this.apiService.generateReport(rId).subscribe((data: any)=>{
        this.proceedToPrint(data);   
      });
    } else {
      this.proceedToPrint(report);
    }

  }

  proceedToPrint(report) {

    let a = document.createElement('a');
    a.setAttribute('href', this.apiService.filesURL + 'generated/kalkulator/' + report);
    a.setAttribute('target', '_blank');
    let action = function() {
      // window.print();
      // return false;
    }
    a.addEventListener('click', action);
    a.click();
    
    // let iframe=document.createElement('iframe');
    // iframe.setAttribute('src',this.apiService.filesURL+'generated/kalkulator/'+report); 
    // iframe.setAttribute('src','http://localhost/cj_blok/bim/files/generated/kalkulator/'+report);    
    // iframe.setAttribute('width','300');
    // iframe.setAttribute('heigth','300');
    // iframe.style.visibility = "hidden"; 
    
    // document.body.appendChild(iframe);
    // iframe.onload=function(){
    //   iframe.contentWindow.focus();
    //   iframe.contentWindow.print();
    // }
    

  }

  
  createXML(id?: number){
    const rId = id !== null && id !== undefined ? id : this.currentResultId;
    this.apiService.createXML(rId);
  }


  deleteResult(rId) {
    return new Promise((resolve, reject) => {
      this.apiService.deleteResult(rId).subscribe((data: any) => {
        this.removeResultFromList(rId);
        resolve(true);
      });
    });
  }

  removeResultFromList(rId) {
    for (let i = 0; i < this.resultsList.length; i++) {
      if (this.resultsList[i].css_id == rId) {
        this.resultsList.splice(i, 1);
        break;
      }
    }
    return this.resultsList;
  }

  get result() {
    return this._result;
  }

  set result(data) {
    this._result = data;
  }

  get resultsList() {
    return this._resultsList;
  }

  set resultsList(data) {
    this._resultsList = data;
  }

  get currentResultId() {
    return this._currentResultId;
  }

  set currentResultId(data) {
    this._currentResultId = data;
  }

  get responseCounter() {
    return this._responseCounter;
  }

  set responseCounter(data) {
    this._responseCounter = data;
  }
}
