import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ComplexConstructionsService } from './complex-constructions.service';
import { CcParametersService } from './cc-parameters.service';

@Injectable({
  providedIn: 'root'
})
export class PathsService {

  private _solutionsPath: any;
  private _filteredCCs = {};

  constructor(
    private apiService: ApiService,
    private ccParamsService: CcParametersService,
    private ccService: ComplexConstructionsService
    ) { }

  init() {  
    return new Promise((resolve, reject) => {
      this.apiService.getCCSelectedPath().subscribe((data: any) => {
        this.solutionsPath = data;
        for (let i = 0; i < this.solutionsPath.length; i++) {
          this.solutionsPath[i].ccsp_path = JSON.parse(this.solutionsPath[i].ccsp_path);
        }
        this.filterAllCCs(true);
        resolve(true);
      });
    });
  }

  public checkPath(path) {
    this.filterAllCCs(false);
    let foundCCs = [];
    let pathArray = [];
    for (let i = 0; i < this.solutionsPath.length; i++) {
      let solPath = this.solutionsPath[i].ccsp_path;
      let found = false;

      if (solPath.length < path.length) {
        continue;
      } else {
        for (const step in path) {
          if (solPath[step] && solPath[step] == path[step]) {
            found = true;
          } else {
            found = false;
            break;
          }

        }
        if (found) {
          foundCCs.push(this.solutionsPath[i].ccsp_cc_id);
          pathArray.push(this.solutionsPath[i]);
        }

      }
    }
    foundCCs = foundCCs.filter((item, index) => foundCCs.indexOf(item) == index);
    for (let i = 0; i < foundCCs.length; i++) {
      this.filteredCCs[foundCCs[i]] = true;
    }
    // console.log(pathArray)
    this.setDefaultParams(pathArray);
    return this.filteredCCs;

  }

  setDefaultParams(paths) {
    const defaults = {};
    for (let i = 0; i < paths.length; i++ ) {
      if (!defaults[paths[i].ccsp_cc_id]) {
        defaults[paths[i].ccsp_cc_id] = [];
      }
      if (paths[i].ccsp_default_params) {
        defaults[paths[i].ccsp_cc_id].push(JSON.parse(paths[i].ccsp_default_params));
      }
    }
    this.ccParamsService.setDefaultParams(defaults);
  }

  filterAllCCs(val) {
    if (this.ccService.CCs) {
      for (let i = 0; i < this.ccService.CCs.length; i++) {
        this.filteredCCs[this.ccService.CCs[i].id_complex_constructions] = val;
      }
    }
  }

  get filteredCCs() {
    return this._filteredCCs;
  }

  get solutionsPath() {
    return this._solutionsPath;
  }

  set solutionsPath(data) {
    this._solutionsPath = data;
  }

}
