import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CcProductsService } from 'src/app/_services/cc-products.service';
import { DiscountCodesService } from 'src/app/_services/discount-codes.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-summary-bar',
  templateUrl: './summary-bar.component.html',
  styleUrls: ['./summary-bar.component.scss']
})
export class SummaryBarComponent implements OnInit {

  @Input() discOption: boolean = false;
  @Input() buttonText: string = null;
  @Input() buttonRoute: string = null;
  
  oldPrice = null;
  totalPrice: number;

  ccSubscribe: Subscription;

  solutionResult = {};


  isUser: boolean = false;
  user_type: number = null;
  subscription: Subscription;

  discountValue: number = null;
  discountCodeValue: string = null;
  discount = null;
  discountedPrice = null;
  discountWarning = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private ccProductsService: CcProductsService,
    private modalService: NgbModal,
    private discountCodesService: DiscountCodesService
    ) { 
      
      this.subscription = this.userService.user$.subscribe(data => {
        this.isUser = this.userService.isUserSignedIn();
      });
    }

  ngOnInit(): void {
    this.oldPrice = null;
    this.ccSubscribe = this.ccProductsService.currentSolution.subscribe(data =>  {
      // console.log(data)
      
      this.solutionResult = data;
      this.discountValue = this.ccProductsService.discount;
      this.ccProductsService.setProductsPrices();
      this.sumTotalPrice();
      // console.log(this.discountValue)
      // this.sumGroupsPrices();

      this.subscription = this.userService.user$.subscribe(data => {
        this.isUser = this.userService.isUserSignedIn();
        if (!this.isUser) {
        } else {
          this.user_type = this.userService.getUserType();
          // this.initCustomAmounts();
        }  
      });
      this.isUser = this.userService.isUserSignedIn();
    });

  }
  
  ngOnDestroy(){
    this.ccSubscribe.unsubscribe();
  }
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  }
  keyPress(event){
    // console.log(event)
    if(event.key=="Enter"){
      if(this.isUser){
        this.submitDiscount();
      }else{
        this.submitDiscountCode();
      }
    }
  }
  submitDiscount(){
    // console.log(this.discountValue)
    if(this.discountValue>35)
      this.discountValue = 35
    this.ccProductsService.setDiscount(this.discountValue)
  }

  submitDiscountCode(){
    let dcPromise = this.discountCodesService.getCode(this.discountCodeValue)
    dcPromise.then( () => {
      if(this.discountCodesService.discountValue) {
        this.discountValue = this.discountCodesService.discountValue;
        this.ccProductsService.setDiscount(this.discountCodesService.discountValue)
        this.discountWarning = false;
      } else {
        this.discountValue = 0;
        this.ccProductsService.setDiscount(0);
        this.discountWarning = true;
      }
    })
  }

  sumTotalPrice() {
    let totalPrice = 0;
    // let totalPriceNetto = 0;
    for (let layerId in this.solutionResult) {
      if (this.solutionResult[layerId].product != null && this.solutionResult[layerId].amount != null) {
        totalPrice += parseFloat(this.solutionResult[layerId].prices.total_brutto);
        // totalPriceNetto += parseFloat(this.solutionResult[layerId].prices.total_netto);
      }

    }
    // console.log(this.discountValue)
    if(typeof this.discountValue == 'string')
      this.discountValue = parseInt(this.discountValue);

    if(this.discountValue){
      this.discountedPrice = totalPrice * (1 - this.discountValue / 100)
    }else{
      this.discountedPrice = null;
    }
    // console.log(this.discountedPrice)
    this.totalPrice = this.numberWithCommas(totalPrice.toFixed(2));
    // this.totalPriceNetto = this.numberWithCommas(totalPriceNetto.toFixed(2));
  }

  
  goToPallets(){
    this.router.navigate(['/pallets/1']);
  }
  goToRoute(){
    this.router.navigate([this.buttonRoute]);
  }


  clientDataModal(content) {
    // this.modalService.open
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {
    });

  }
}
