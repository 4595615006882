import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/_services/api.service';
import { UserService } from 'src/app/_services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})
export class UserPanelComponent implements OnInit {

  userPsswd: string;
  userLogin: string;

  invalidData = false;
  subscription: Subscription;
  processing = false;

  constructor(
    private modalService: NgbModal,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.invalidData = false;
  }

  signIn() {
    this.processing = true;
    const res = this.userService.signIn(this.userLogin, this.userPsswd);
    res.then(val => {
      if (val == null) {
        this.invalidCredentials();
      } else {
        this.resetFormData();
        this.closeModal();
      }
      this.processing = false;
    });
  }

  keyPress(event: any) {
    if (event.keyCode == 13) {
      if (this.userLogin != '' && this.userLogin != '' && this.userLogin != null && this.userLogin != null) {
        this.signIn();
      }
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  resetFormData() {
    this.userPsswd = null;
    this.userLogin = null;
  }

  invalidCredentials() {
    this.invalidData = true;
  }
}
