import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app.component';
import { ParametersComponent } from './components/parameters/parameters.component';
import { UserPanelComponent } from './components/user-panel/user-panel.component';
import { HeadBarComponent } from './components/head-bar/head-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { CookieService } from 'ngx-cookie-service';
import { SelectPathComponent } from './components/select-path/select-path.component';
import { PathsService } from './_services/paths.service';
import { OfferComponent } from './components/offer/offer.component';
import { ParamsComponent } from './components/params/params.component';
import { ResultComponent } from './components/result/result.component';
import { ClientModalComponent } from './components/client-modal/client-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ComplexConstructionsService } from './_services/complex-constructions.service';
import { OffersComponent } from './components/offers/offers.component';
import { DiscountTabComponent } from './components/discount-tab/discount-tab.component';
import { PalletsComponent } from './components/pallets/pallets.component';
import { SystemNameService } from './_services/system-name.service';
import { SummaryBarComponent } from './components/summary-bar/summary-bar.component';
import { StepsBarComponent } from './components/steps-bar/steps-bar.component';
import { PalletsService } from './_services/pallets.service';

// export function ccParametersFactory(provider:CcParametersService){
//   return () => provider.init();
// }
// export function ccProductsFactory(provider:CcProductsService){
//   return () => provider.init(1);
// }

export function palletsFactory(provider:PalletsService){
  return () => provider.init();
}

export function pathsFactory(provider: PathsService) {
  return () => provider.init();
}
export function systemNamesFactory(provider: SystemNameService) {
  return () => provider.init(1);
}
export function ccFactory(provider: ComplexConstructionsService) {
  return () => provider.init();
}



@NgModule({
  declarations: [
    AppComponent,
    ParametersComponent,
    UserPanelComponent,
    HeadBarComponent,
    SelectPathComponent,
    OfferComponent,
    ParamsComponent,
    ResultComponent,
    ClientModalComponent,
    NavbarComponent,
    OffersComponent,
    DiscountTabComponent,
    PalletsComponent,
    SummaryBarComponent,
    StepsBarComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    NgSelectModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [
    CookieService,
    PathsService, {
    provide: APP_INITIALIZER, useFactory: pathsFactory, deps: [PathsService], multi: true
    },
    SystemNameService, {
    provide: APP_INITIALIZER, useFactory: systemNamesFactory, deps: [SystemNameService], multi: true
    },
    ComplexConstructionsService, {
    provide: APP_INITIALIZER, useFactory: ccFactory, deps: [ComplexConstructionsService], multi: true
    },
    PalletsService, {
    provide: APP_INITIALIZER, useFactory: palletsFactory, deps: [PalletsService], multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
