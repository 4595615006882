import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CcParametersService } from './cc-parameters.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { provideRoutes } from '@angular/router';
// import { ActivatedRoute, ParamMap } from '@angular/router';
// import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CcProductsService {

  private _layers = null;
  private _solutionResult = {};
  private _availableProducts = {};
  private solutionSource = new BehaviorSubject<any>(null);
  currentSolution = this.solutionSource.asObservable();
  private _discount:number = 0;
  private _custom_amounts = {};
  private _discountsGroups = [];
  
  private _initiated : boolean = false;
  public get initiated() : boolean {
    return this._initiated;
  }
  public set initiated(v : boolean) {
    this._initiated = v;
  }
  
  constructor(
    private ccParametersService: CcParametersService,
    // private route: ActivatedRoute,
    private apiService: ApiService
  ) { }

  init(id){  

    return new Promise((resolve, reject) => {
      this.apiService.getCCProducts(id).subscribe((data: any) => {    
        // data=this.sortLayersByCharacter(data)
        this.layers = data;
        for (let group in this.layers) {
          for (let i = 0; i < this.layers[group].layers.length; i++) {
            if (this.layers[group].layers[i].amounts) {
              this.layers[group].layers[i].amounts.cc_amount_params =
              JSON.parse(this.layers[group].layers[i].amounts.cc_amount_params);
            }
            
          }
        }
        this.refreshProductsList();
        this.initiated=true;
        resolve(true)
      })
    })
  }

  refreshProductsList() {
    this.setProducts();
    this.setProductsAmounts();
    this.setCustomAmountsAndRecalculate(this.custom_amounts);
    // this.setProductsPrices();
    this.solutionSource.next(this.solutionResult);
  }

  setDiscount(disc) {
    this.discount = disc;
    this.solutionSource.next(this.solutionResult);
  }
  setDiscounts(discs) {
    this.discountGroups = discs;
    this.solutionSource.next(this.solutionResult);
  }

  checkIfAnyDiscountOrMargin() {
    
    for ( let discount of this.discountGroups) {
      if (discount.discount || discount.margin) {
        return true;
      }
    }
    return false;
  }
  /**
   * 
   * @param source_price cena z której przelicza się oryginalna cene
   * 1 - cena podstawowa
   * 2 - cena po rabacie
   * 
   */
  calculateOldPrice(source_price){
    let oldPrice = {
      netto: 0,
      brutto: 0,
    };

    for ( let layer_id in this.solutionResult) {
      let layer = this.solutionResult[layer_id];
      let amount = layer.amount
      if ( source_price == 1) {
        oldPrice.brutto += parseFloat(layer.prices.brutto_unit) * amount;
        oldPrice.netto += parseFloat(layer.prices.netto_unit) * amount;
      } else if ( source_price == 2) {
        oldPrice.brutto += parseFloat(layer.prices.discount_unit_brutto) * amount;
        oldPrice.netto += parseFloat(layer.prices.discount_unit_netto) * amount;
      }
    }
    return oldPrice;
  }

  sortLayersByCharacter(layers) {
    let layersByCharacter={};
    let result=[];
    for (let i = 0; i < layers.length; i++) {
      if (layersByCharacter[layers[i].layer_character] == undefined)
        layersByCharacter[layers[i].layer_character] = [];

      layersByCharacter[layers[i].layer_character].push(layers[i]);
    }
    for (var key in layersByCharacter) {
      result = result.concat(layersByCharacter[key]);
    }

    return result;
  }

  initCustomAmounts() {
    this.custom_amounts = {};
    for(let layer_id in this.solutionResult){
      if(this.custom_amounts[layer_id] == undefined)
        this.custom_amounts[layer_id] = {amount: null, custom: false};  

        this.custom_amounts[layer_id].amount = this.solutionResult[layer_id].amount;
        this.custom_amounts[layer_id].custom = false;   
    }
  }

  setCustomAmountsAndRecalculate(custom_amounts) {
    for(let layer_id in custom_amounts) {
      if(custom_amounts[layer_id].custom) {
        this.solutionResult[layer_id].amount = custom_amounts[layer_id].amount;
      }
    }
    this.setProductsPrices();
  }

  setCustomAmounts(){
    for(let layer_id in this.solutionResult){
      if(this.custom_amounts[layer_id] == undefined) {
        this.custom_amounts[layer_id] = {amount: null, custom: false}
      }

      if(!this.custom_amounts[layer_id].custom) {
        this.custom_amounts[layer_id].amount = this.solutionResult[layer_id].amount;
      }
    }

  }

  deleteCustomAmount(layer_id) {
    this.custom_amounts[layer_id].custom = false;
    this.setProductsAmounts();
    this.setCustomAmountsAndRecalculate(this.custom_amounts);
    this.custom_amounts[layer_id].amount = this.solutionResult[layer_id].amount;
    
  }

  amountChange(layer_id) {
    this.custom_amounts[layer_id].custom = true;
    this.setCustomAmountsAndRecalculate(this.custom_amounts);
  }

  setProducts() {
    let result = {};
    this.ccParametersService.clearInputParams();
    // this.solutionResult={};
    let newSolutionResults = {}
    for(let group in this.layers) {
      let layers = this.layers[group].layers;
      for (let i = 0; i < layers.length; i++) {
        // result[this.layers[i].id_complex_constructions_layers]=[];

        let products = [];
        let product = null;
        let layer_products = layers[i].products;

        for(let j=0;j<layer_products.length;j++){
          product = null;
          if(layer_products[j].product_rules.length == 0){
            product = layer_products[j]
          }else{
            let valid = this.checkIfLayerRulesMet(layers[i].id_complex_constructions_layers,layer_products[j].product_rules);
            
            if (valid) {
              product = layer_products[j];
            }          
          }

          if (product) {
            products.push(product);
          }
        }
      
        this.availableProducts[layers[i].id_complex_constructions_layers] = products;
        if (!this.solutionResult[layers[i].id_complex_constructions_layers]) {
          this.solutionResult[layers[i].id_complex_constructions_layers] = {"product": null, "amount": null}
          // newSolutionResults[layers[i].id_complex_constructions_layers]={"product":null,"amount":null}
        }

        if (this.availableProducts[layers[i].id_complex_constructions_layers].length > 0) {
          if (!this.solutionResult[layers[i].id_complex_constructions_layers].product) {
            this.solutionResult[layers[i].id_complex_constructions_layers].product = this.availableProducts[layers[i].id_complex_constructions_layers][0].id_products;
            // newSolutionResults[layers[i].id_complex_constructions_layers].product=this.availableProducts[layers[i].id_complex_constructions_layers][0].id_products
          } else {
            let isProductOnList = this.isProductOnList(this.solutionResult[layers[i].id_complex_constructions_layers].product,this.availableProducts[layers[i].id_complex_constructions_layers]);
            if (!isProductOnList) {
              this.solutionResult[layers[i].id_complex_constructions_layers].product = this.availableProducts[layers[i].id_complex_constructions_layers][0].id_products
              // newSolutionResults[layers[i].id_complex_constructions_layers].product=this.availableProducts[layers[i].id_complex_constructions_layers][0].id_products
            } else {
            }
          }

          this.ccParametersService.setParamsValues(this.getProductFromLayer(layers[i].id_complex_constructions_layers, this.solutionResult[layers[i].id_complex_constructions_layers].product));
        }else{
          
          // newSolutionResults[layers[i].id_complex_constructions_layers]={"product":null,"amount":null}
          this.solutionResult[layers[i].id_complex_constructions_layers] = {"product": null, "amount": null};
        }        
        newSolutionResults[layers[i].id_complex_constructions_layers] =
        this.solutionResult[layers[i].id_complex_constructions_layers];
      }
    }
    this.solutionResult = newSolutionResults
  }

  getProductFromLayer(layer_id, prod_id) {
    let result = null;
    for (let group in this.layers) {
      let layers = this.layers[group].layers;
      for (let i = 0; i < layers.length; i++) {
        if (layers[i].id_complex_constructions_layers == layer_id) {
          for (let j = 0; j < layers[i].products.length; j++) {
            if (layers[i].products[j].id_products == prod_id) {
              result=  layers[i].products[j];
              return result;
            }
          }
        }
      }
    }
    return result;
  }

  isProductOnList(id_prod, products) {
    for (let i = 0; i < products.length; i++) {
      if (products[i].id_products == id_prod) {
        return true;
      }
    }
    return false;
  }

  checkIfLayerRulesMet(layer_id, product_rules) {
    let groups_rules = {};
    let inputRulesCounter = 0;
    let product_valid = true;
    for (let i = 0; i < product_rules.length; i++) {

      //jesli regula jest na liscie parametrow zaleznych OD produktu to nie jest zaliczana do wymogow
      
      if (product_rules[i].cc_rules_group == 0) {
        inputRulesCounter++;
        continue;
      }

      if (groups_rules[product_rules[i].cc_rules_group] == undefined) {
        groups_rules[product_rules[i].cc_rules_group] = {};
      }

      if (groups_rules[product_rules[i].cc_rules_group][product_rules[i].cc_param_id] == undefined) {
        groups_rules[product_rules[i].cc_rules_group][product_rules[i].cc_param_id] = [];
      }
    
      groups_rules[product_rules[i].cc_rules_group][product_rules[i].cc_param_id].push(product_rules[i]);
    }
    /*
    Wynik formatowania danych:
    grupy{
      id_grupy:{
        id_parametru:[
          rule1,
          rule2,
          ...
        ],
        id_parametru2:[
          ...
        ]
      },
      id_grupy2:{
        ...
      }
    }

    zaleznosc => warunek jest spelniony jesli w danej grupie 
    jest spelniony przynajmniej dla jednej reguly dla kazdego parametru
    */  
    let groups_keys = Object.keys(groups_rules);
    let group_valid = false;

    // iteracja po grupach regul
    for (let key of groups_keys) {
      let params_keys = Object.keys(groups_rules[key]);
      let param_valid = false;

      // iteracja po parametrach
      for (let rule of params_keys) {

        // iteracja po regulach dla danego parametru
        for (let i = 0; i < groups_rules[key][rule].length; i++) {
          param_valid = false;
          // jesli w danej grupie ktoras z regul odpowiada parametrowi, regula parametru jest spelniona
          if (groups_rules[key][rule][i].cc_rules_value == (typeof this.ccParametersService.paramValues[rule] == 'boolean' ? (this.ccParametersService.paramValues[rule]? 1:0) : this.ccParametersService.paramValues[rule] )) {
            param_valid = true;        
            break;
          }
        }
        if (!param_valid) {
          break;
        }
      }
      // jesli grupa nie spelnia wymagan to iteracja leci do kolejnej.
      // jesli grupa spelnia wymagania to nie ma potrzeby sprawdzac dalej - produkt spelnia wymog.
      if (param_valid) {
        group_valid = true;
        break;
      } else {
        group_valid = false;
      }
    }

    //w razie blednego doboru produktow, sprawdzic tutaj!
    if(inputRulesCounter == product_rules.length) {
      product_valid = true;
    } else {
      product_valid = group_valid;
    }

    return product_valid;
  }



  setProductsAmounts() {
    let finalAmount: number = null;
    let params_names = null;
    // console.log(this.layers)
    // console.log(this.ccParametersService.paramValues)
    // console.log(this.ccParametersService.rawParams)
    for (let j = 0; j < this.layers.length; j++) {
      let layers = this.layers[j].layers;
      for (let i = 0; i < layers.length; i++) {
        finalAmount = null;
        let amountFormula = null;         

        // console.warn(layers[i])
        // console.warn(this.solutionResult[layers[i].id_complex_constructions_layers].product)
        if (!layers[i].amounts) {
          continue;
        } else if (!this.solutionResult[layers[i].id_complex_constructions_layers].product) {
          continue;
        } else {
          switch (parseInt(layers[i].amounts.cc_amount_type)) {
            case 0:
              finalAmount = parseInt(layers[i].amounts.cc_amount_value);
              break;

            case 1:
              amountFormula = layers[i].amounts.cc_amount_value;

              params_names = Object.keys(layers[i].amounts.cc_amount_params);
              for (const param in params_names) {
                let val = this.ccParametersService.rawParams[layers[i].amounts.cc_amount_params[params_names[param]]].param_values[this.ccParametersService.paramValues[layers[i].amounts.cc_amount_params[params_names[param]]]]
                amountFormula = amountFormula.replace(
                  params_names[param],
                  val
                  // this.ccParametersService.paramValues[layers[i].amounts.cc_amount_params[params_names[param]]]
                  );
                  // console.log(
                  //   this.ccParametersService.paramValues[layers[i].amounts.cc_amount_params[params_names[param]]],
                  //   layers[i].amounts.cc_amount_params[params_names[param]],
                  //   val
                  //   )
              }
              // console.log(amountFormula)
              finalAmount = eval(amountFormula);

              break;

            case 2:
              let wholeFormula = layers[i].amounts.cc_amount_value;
              params_names = Object.keys(layers[i].amounts.cc_amount_params);

              for (let param in params_names) {
                let val = this.ccParametersService.rawParams[layers[i].amounts.cc_amount_params[params_names[param]]].param_values[this.ccParametersService.paramValues[layers[i].amounts.cc_amount_params[params_names[param]]]]
                wholeFormula = wholeFormula.split(params_names[param])
                .join(
                  val
                  // this.ccParametersService.paramValues[layers[i].amounts.cc_amount_params[params_names[param]]]
                  );
              }
              const aFormula = wholeFormula.split('?');
              const condition = aFormula[0];
              const aAmountFormulas = aFormula[1].split(';');
              try {
                if (eval(condition)) {
                  finalAmount = eval(aAmountFormulas[0]);
                } else {
                  finalAmount = eval(aAmountFormulas[1]);
                }
              } catch(error) {
                console.warn('undefined params');
                for (let param in params_names) {
                  if (!this.ccParametersService.paramValues[layers[i].amounts.cc_amount_params[params_names[param]]]) {
                    console.log(params_names[param], layers[i].amounts.cc_amount_params[params_names[param]]);
                  }
                }
              }

              break;
            case 3:
              // case z cala formula bazuje na param VAL a nie na wartosciach!! Np param[id]=true to warunek sprawdza po czy ==id a nie 'true'!
              amountFormula = layers[i].amounts.cc_amount_value;

              params_names = Object.keys(layers[i].amounts.cc_amount_params);
              
              for(let param in params_names){
                // let val = this.ccParametersService.rawParams[layers[i].amounts.cc_amount_params[params_names[param]]].param_values[this.ccParametersService.paramValues[layers[i].amounts.cc_amount_params[params_names[param]]]]
                amountFormula = 
                  amountFormula.split(params_names[param])
                  .join(
                    // val
                    this.ccParametersService.paramValues[layers[i].amounts.cc_amount_params[params_names[param]]]
                    );
              }
              // console.log(amountFormula)
              amountFormula = amountFormula.split(';').join(':');
              finalAmount = eval(amountFormula);
              break;

          }
        }
        this.solutionResult[layers[i].id_complex_constructions_layers].amount = finalAmount;

      }
    }
  }

  setProductsPrices() {
    for (let layer_id in this.solutionResult) {
      if (this.solutionResult.hasOwnProperty(layer_id)) {
        

        if (this.solutionResult[layer_id].product !== null && this.solutionResult[layer_id].amount) {
          let prod=this.getProductFromLayer(layer_id, this.solutionResult[layer_id].product);
          if (!prod) {
            continue;
          }
          this.solutionResult[layer_id].prices = {
            netto_unit:prod.product_data.prices.netto_szt,
            brutto_unit:prod.product_data.prices.brutto_szt,
            netto_pack:prod.product_data.prices.netto_opakowanie,
            brutto_pack:prod.product_data.prices.brutto_opakowanie,
            total_netto:prod.product_data.prices.netto_szt * this.solutionResult[layer_id].amount,
            total_brutto:prod.product_data.prices.brutto_szt * this.solutionResult[layer_id].amount,
          }; 

        }else{
          this.solutionResult[layer_id].prices = {
            netto_unit:0,
            brutto_unit:0,
            netto_pack:0,
            brutto_pack:0,
            total_netto:0,
            total_brutto:0,
          };
        }
      }
    }
  }

  getDiscountGroupById(id) {
    const results = this.discountGroups.find( obj => {
      return obj.id == id;
    });
    return results;
  }

  get discountGroups() {
    return this._discountsGroups;
  }

  set discountGroups(data) {
    this._discountsGroups = data;
  }

  get custom_amounts() {
    return this._custom_amounts;
  }

  set custom_amounts(data) {
    this._custom_amounts = data;
  }

  get layers() {
    return this._layers;
  }

  set layers(data) {
    this._layers = data;
  }
  get discount() {
    return this._discount;
  }

  set discount(data) {
    this._discount = data;
  }




  get solutionResult() {
    return this._solutionResult;
  }

  set solutionResult(data) {
    this._solutionResult = data;
  }

  get availableProducts() {
    return this._availableProducts;
  }

  set availableProducts(data) {
    this._availableProducts = data;
  }
}
