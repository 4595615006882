import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResultsService } from 'src/app/_services/results.service';
import { ComplexConstructionsService } from 'src/app/_services/complex-constructions.service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/_services/user.service';
import { CcProductsService } from 'src/app/_services/cc-products.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemNameService } from 'src/app/_services/system-name.service';
import { CcParametersService } from 'src/app/_services/cc-parameters.service';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit, OnDestroy {

  cc = null;
  result = null;


  isUser = false;
  user_type: number = null;
  subscription: Subscription;
  discountValue: number = null;
  sysNameSubscribe: Subscription;
  brick = null;
  sysName: string;
  no_sys: boolean;
  sys_image: string;
  constructor(
    private resultsService: ResultsService,
    private route: ActivatedRoute,
    private userService: UserService,
    private ccService: ComplexConstructionsService,
    private ccParamsService: CcParametersService,
    private modalService: NgbModal,
    private systemNameService: SystemNameService,
    private router: Router,
    private ccProductsService: CcProductsService,
    ) {
    this.isUser = this.userService.isUserSignedIn();
    }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    const resPromise = this.resultsService.getResults(id, null);
    resPromise.then(() =>  {
      this.result = this.resultsService.result;
      // console.log(this.result)
      // console.log(this.result.css_user_id, this.userService.getUserId(),this.userService.getUserType())
      
      // if (this.result.css_user_id != this.userService.getUserId() && this.userService.getUserType() != 1) {
      //   this.router.navigate(['/']);
      // }
      
      this.discountValue = this.result.css_discount;
      this.ccProductsService.setDiscount(this.discountValue);
      this.cc = this.ccService.getCC(this.result.css_cc_id);
    });

    this.sysNameSubscribe = this.systemNameService.name$.subscribe(data =>  {
      this.sysName = data;
      this.sys_image=this.systemNameService.sys_image;
      this.brick = this.systemNameService.brickImg
      this.no_sys=this.systemNameService.no_sys;
      // console.log(this.brick)
    });
    this.sys_image=this.systemNameService.sys_image;

    this.subscription = this.userService.user$.subscribe(data => {
      this.isUser = this.userService.isUserSignedIn();
      if (!this.isUser) {
        this.router.navigate(['/']);
      } else {
        this.user_type = this.userService.getUserType();
      }

    });
    if (!this.isUser) {
      this.router.navigate(['/']);
    } else {
      this.user_type = this.userService.getUserType();
    }
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
    this.sysNameSubscribe.unsubscribe();
  }
  // submitDiscount(){
  //   this.ccProductsService.setDiscount(this.discountValue);
  // }

  clientDataModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }
  createXML() {
    const xmlPromise = this.resultsService.createXML();
  }
}
