import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CcProductsService } from 'src/app/_services/cc-products.service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/_services/user.service';
import { DiscountCodesService } from 'src/app/_services/discount-codes.service';
import { SystemNameService } from 'src/app/_services/system-name.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit, OnDestroy {
  @Input() cc: any;
  @Input() result: any = null;


  layers = null;
  availableProducts = {};
  solutionResult = {};
  totalPrice: string = null;
  totalPriceNetto: string = null;
  groupsPrices: any = {};
  groupsPricesAdditional: any = {};

  oldPrice: any = {
    brutto: 0,
    netto: 0,
  };

  // custom_amounts: any = {};
  isUser: boolean = false;
  user_type: number = null;

  subscription: Subscription;
  ccSubscribe: Subscription;
  sysNameSubscribe: Subscription;

  discountValue = null;
  discountCodeValue: string = null;
  discount = null;
  discountedPrice = null;
  discountWarning = false;
  sysName: string;


  constructor(
    private userService: UserService,
    private ccProductsService: CcProductsService,
    private discountCodesService: DiscountCodesService,
    private systemNameService: SystemNameService
    ) {
      this.subscription = this.userService.user$.subscribe(data => {
        this.isUser = this.userService.isUserSignedIn();
      });
    }

  ngOnInit(): void {
    this.ccProductsService.setDiscount(0);
    this.discountCodesService.clear();
    this.discountWarning = false;

    const ccProductsPromise = this.ccProductsService.init(this.cc.id_complex_constructions);
    ccProductsPromise.then(() =>  {

      if (this.result && this.result.css_results) {
        this.ccProductsService.solutionResult = this.result.css_results;
        this.discountValue=this.result.css_discount;
        this.ccProductsService.setDiscount(this.discountValue)
      }
      this.solutionResult = this.ccProductsService.solutionResult;
      this.availableProducts = this.ccProductsService.availableProducts;
      this.layers = this.ccProductsService.layers;

      // this.initCustomAmounts();
      this.ccProductsService.setProductsPrices();
      this.sumTotalPrice();
      this.sumGroupsPrices();
    });

    this.subscription = this.userService.user$.subscribe(data => {
      this.isUser = this.userService.isUserSignedIn();
      if (!this.isUser) {
      } else {
        this.user_type = this.userService.getUserType();
        // this.initCustomAmounts();
      }

    });

    this.oldPrice = null;
    this.ccSubscribe = this.ccProductsService.currentSolution.subscribe(data =>  {
      this.solutionResult = data;
      this.ccProductsService.setProductsPrices();
      this.sumTotalPrice();
      this.sumGroupsPrices();

      // if (this.isUser) {
      //   this.setCustomAmounts();
      // }
    });
    this.isUser = this.userService.isUserSignedIn();
    if (!this.isUser) {
    } else {
      this.user_type = this.userService.getUserType();
      // this.initCustomAmounts();
      // this.custom_amounts = this.ccProductsService.custom_amounts;
    }

    this.sysNameSubscribe = this.systemNameService.name$.subscribe(data =>  {
      this.sysName = data;
    });

    this.sysName = this.systemNameService.sysName;
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
    this.ccSubscribe.unsubscribe();
  }
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  }

  onChange() {
    this.ccProductsService.refreshProductsList();
    this.solutionResult = this.ccProductsService.solutionResult;
    this.sumTotalPrice();
    this.sumGroupsPrices();
  }

  submitDiscount(){
    console.log(this.discountValue)
    this.ccProductsService.setDiscount(this.discountValue)
  }

  submitDiscountCode(){
    let dcPromise = this.discountCodesService.getCode(this.discountCodeValue)
    dcPromise.then( () => {
      if(this.discountCodesService.discountValue) {
        this.discountValue = this.discountCodesService.discountValue;
        this.ccProductsService.setDiscount(this.discountCodesService.discountValue)
        this.discountWarning = false;
      } else {
        this.discountValue = 0;
        this.ccProductsService.setDiscount(0);
        this.discountWarning = true;
      }
    })
  }

  getProductFromLayer(id) {
    // console.log(id);
    // console.log(this.ccProductsService.getProductFromLayer(id, this.solutionResult[id].product))
    return this.ccProductsService.getProductFromLayer(id, this.solutionResult[id].product);
  }

  sumGroupsPrices() {
    this.groupsPrices =  {};
    this.groupsPricesAdditional = {};

    if (this.layers) {
      for (let i = 0; i < this.layers.length; i++) {
        this.groupsPrices[this.layers[i].layer_character_id] = {
          netto: 0,
          brutto: 0,
        };
        this.groupsPricesAdditional[this.layers[i].layer_character_id] = {
          netto_unit: 0,
          brutto_unit: 0,
          
          total_netto: 0,
          total_brutto: 0
        };
        // console.log(this.solutionResult)
        let layerNull = true;
        for (let j = 0; j < this.layers[i].layers.length; j++) {
          let price = 0;
          let priceNetto = 0;
          const layerId = this.layers[i].layers[j].id_complex_constructions_layers;

          if (this.solutionResult[layerId] && this.solutionResult[layerId].product != null && this.solutionResult[layerId].amount != null) {
            price += parseFloat(this.solutionResult[layerId].prices.total_brutto);
            priceNetto += parseFloat(this.solutionResult[layerId].prices.total_netto);
            
            this.groupsPricesAdditional[this.layers[i].layer_character_id].netto_unit += parseFloat(this.solutionResult[layerId].prices.netto_unit);
            this.groupsPricesAdditional[this.layers[i].layer_character_id].brutto_unit += parseFloat(this.solutionResult[layerId].prices.brutto_unit);
            
            this.groupsPricesAdditional[this.layers[i].layer_character_id].total_netto += parseFloat(this.solutionResult[layerId].prices.total_netto);
            this.groupsPricesAdditional[this.layers[i].layer_character_id].total_brutto += parseFloat(this.solutionResult[layerId].prices.total_brutto);
            layerNull = false;
          } else if (this.solutionResult[layerId] && this.solutionResult[layerId].product != null && this.solutionResult[layerId].amount == null) {

          }

          this.groupsPrices[this.layers[i].layer_character_id].brutto += price;
          this.groupsPrices[this.layers[i].layer_character_id].netto += priceNetto;
        }

        if (layerNull) {
          this.groupsPrices[this.layers[i].layer_character_id] = null;
          this.groupsPricesAdditional[this.layers[i].layer_character_id] = null;
        } else {
          this.groupsPrices[this.layers[i].layer_character_id].brutto = 
            this.numberWithCommas(this.groupsPrices[this.layers[i].layer_character_id].brutto.toFixed(2));
          this.groupsPrices[this.layers[i].layer_character_id].netto = 
            this.numberWithCommas(this.groupsPrices[this.layers[i].layer_character_id].netto.toFixed(2));
        }
        // console.log(this.groupsPricesAdditional)
      }
    }
  }

  sumTotalPrice() {
    let totalPrice = 0;
    let totalPriceNetto = 0;
    for (let layerId in this.solutionResult) {
      if (this.solutionResult[layerId].product != null && this.solutionResult[layerId].amount != null) {
        totalPrice += parseFloat(this.solutionResult[layerId].prices.total_brutto);
        totalPriceNetto += parseFloat(this.solutionResult[layerId].prices.total_netto);
      }

    }
    // if (this.ccProductsService.checkIfAnyDiscountOrMargin()) {
    //   console.log(this.user_type)
    //   if (this.user_type == 2) {
    //     this.oldPrice = this.ccProductsService.calculateOldPrice(1);
    //   } else if (this.user_type == 1) {
    //     this.oldPrice = this.ccProductsService.calculateOldPrice(2);
    //   }
    // } else {
    //   this.oldPrice = null;
    // }
    if(this.discountValue){
      this.discountedPrice = totalPrice * (1 - this.discountValue / 100)
    }else{
      this.discountedPrice = null;
    }
    this.totalPrice = this.numberWithCommas(totalPrice.toFixed(2));
    this.totalPriceNetto = this.numberWithCommas(totalPriceNetto.toFixed(2));
  }

  // initCustomAmounts() {
  //   this.ccProductsService.initCustomAmounts();
  //   this.custom_amounts = this.ccProductsService.custom_amounts;
  // }


  // setCustomAmounts() {
  //   this.ccProductsService.setCustomAmounts();
  //   this.custom_amounts = this.ccProductsService.custom_amounts;
  // }

  // deleteCustomAmount(layerId) {
  //   this.ccProductsService.deleteCustomAmount(layerId);
  //   this.solutionResult = this.ccProductsService.solutionResult;
  // this.custom_amounts = this.ccProductsService.custom_amounts;
  //   this.sumTotalPrice();
  //   this.sumGroupsPrices();
  // }

  // amountChange(layerId) {
  //   this.ccProductsService.amountChange(layerId);
  //   this.solutionResult = this.ccProductsService.solutionResult;
  //   this.custom_amounts = this.ccProductsService.custom_amounts;
  //   this.sumTotalPrice();
  //   this.sumGroupsPrices();
  // }
}
