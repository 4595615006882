import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CalcParametersService {


  private _parameters:any = null;
  private _selectedParameters:any = {};
  private _currentPath:any = [];

  constructor(
    private apiService: ApiService
    ) {}  

  init(){
    return new Promise((resolve, reject) => {
      this.apiService.getCalcParams().subscribe((data: any) => { 
        this._parameters = data;
        this.resetPath();
        this.addToPath(3);
        resolve(true);
      })
    })
  }


  addToPath(id) {
    const param = this.getParam(id)
    this._currentPath.push(param);
    return param;
  }

  selectParam(param_id, step_id) {
    this.selectedParameters[param_id] = step_id;
  } 

  goBackOnPath(param_id) {
    for (let i = this.currentPath.length-1; i >= 0; i--) {
      let cccp_id = this.currentPath[i].cccp_id;
      delete this.selectedParameters[cccp_id];
      this.currentPath.splice(i);
      if(cccp_id==param_id)
        break;
    }
  }

  resetPath(){
    this.selectedParameters={};
    this.currentPath=[];
  }

  get currentPath(){
    return this._currentPath;
  }

  set currentPath(data){
    this._currentPath=data;
  }

  getParam(id){
    for(let i=0;i<this.parameters.length;i++){
      if(this.parameters[i].cccp_id==id)
        return this.parameters[i];
    }
    return null;
  }

  get parameters(){
    return this._parameters;
  }

  set parameters(params){
    this._parameters=params;
  }

  set selectedParameters(data){
    this._selectedParameters=data;
  }

  get selectedParameters(){
    return this._selectedParameters;
  }

  getSelectedParameter(id){
    return this._selectedParameters[id];
  }

  setSelectedParameter(id,options){
    this._selectedParameters[id]=options;
  }
}
