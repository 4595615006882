import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ParametersComponent } from './components/parameters/parameters.component';
import { SelectPathComponent } from './components/select-path/select-path.component';
import { OfferComponent } from './components/offer/offer.component';
import { OffersComponent } from './components/offers/offers.component';
import { PalletsComponent } from './components/pallets/pallets.component';


const appRoutes: Routes = [
  { path: '', component: SelectPathComponent},
  { path: 'parameters/:id', component: ParametersComponent },
  { path: 'pallets/:id', component: PalletsComponent },
  { path: 'offer/:id', component: OfferComponent },
  { path: 'offers', component: OffersComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
    // { enableTracing: true } // <-- debugging purposes only
  )
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
