import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CcParametersService } from './cc-parameters.service';
import { CcProductsService } from './cc-products.service';
import { ResultsService } from './results.service';

@Injectable({
  providedIn: 'root'
})
export class PalletsService {
  
  private _pallets : Object;
  public get pallets() : Object {
    return this._pallets;
  }
  public set pallets(v : Object) {
    this._pallets = v;
  }
  
  private _palletsRules : Array<Object>;
  public get palletsRules() : Array<Object> {
    return this._palletsRules;
  }
  public set palletsRules(v : Array<Object>) {
    this._palletsRules = v;
  }
  
  constructor(
    private apiService: ApiService,
    private ccParametersService: CcParametersService,
    private ccProductsService: CcProductsService
    ) { }

  init(){
    this.pallets = {
      total:{base:0,add:0,img:null},
      grouped: {}
    }
    return new Promise((resolve, reject) => {
      this.apiService.getPalletRules().subscribe((data: any)=>{
        // console.log(data)
        for(let i=0;i<data.length;i++){
          data[i].cp_params = JSON.parse(data[i].cp_params);
          data[i]['cp_base_pallets_amount']=parseInt(data[i]['cp_base_pallets_amount'])
        }
        this.palletsRules = data;
        resolve(true)
      })
      
    })
  }
  calculatePallets(){
    const solRes = this.ccProductsService.solutionResult;
    const params = this.ccParametersService.paramValues;
    const rawParams = this.ccParametersService.rawParams
    let palletsTotal = {base:0,add:0,img:null};
    let palletsPerProd = {};
    for(let rule of this.palletsRules){ 
      // console.log(rule['cp_layer_id'],rule['cp_prod_id'],solRes[rule['cp_layer_id']],rule['cp_base_pallets_amount'])     

      let counterBase = 0;
      let counterAdd = 0;
      if(!this.checkRule(rule['cp_rule_for_base'],rule['cp_params'])){
        continue;
      }
      if(solRes[rule['cp_layer_id']] && solRes[rule['cp_layer_id']].product == rule['cp_prod_id']){        
          let val = rawParams[rule['cp_height_id']].param_values[params[rule['cp_height_id']]]
        // console.log(val, rule['cp_min_height'])
        // console.log(rule['cp_prod_id'])
          if(val > rule['cp_min_height']){
            counterBase += rule['cp_base_pallets_amount']
            let additional_products = solRes[rule['cp_layer_id']].amount - (rule['cp_szt_mb']*rule['cp_min_height']);
            counterAdd += Math.ceil(additional_products/rule['cp_max_per_pallet'])
          }else{
            counterBase += rule['cp_base_pallets_amount']
            console.log('equal')
          }
          if(counterAdd>0 || counterBase>0){
            palletsPerProd[rule['cp_prod_id']] = {base:counterBase,add:counterAdd,img:rule['cpr_image'],prior:rule['cpr_priority']};
          }
          // palletsTotal = {
          //   base:palletsTotal.base+palletsPerProd[rule['cp_prod_id']].base,
          //   add:palletsTotal.add+palletsPerProd[rule['cp_prod_id']].add,
          //   img:rule['cpr_image']
          // }
            
      }
    }
    for(let ppp in palletsPerProd) {
      if(palletsPerProd[ppp]['prior']==1){
        palletsTotal.base = palletsPerProd[ppp]['base'];
        palletsTotal.add += palletsPerProd[ppp]['add']; 
        palletsTotal.img = palletsPerProd[ppp]['img']; 
      }else {
        if(Object.keys(palletsPerProd).length>1){  
          palletsTotal.add += palletsPerProd[ppp]['add']+palletsPerProd[ppp]['base'];
        }else{
          palletsTotal.base = palletsPerProd[ppp]['base'];
          palletsTotal.add += palletsPerProd[ppp]['add']; 
          palletsTotal.img = palletsPerProd[ppp]['img']; 
        }
      }
    }
    this.pallets={
      total:palletsTotal,
      grouped: palletsPerProd
    }
    return this.pallets;
  }

  checkRule(rule,params){
    const paramValues = this.ccParametersService.paramValues;
    const rawParams = this.ccParametersService.rawParams
    let r = rule;
    if(rule == null){
      return true;
    }
    for(let p in params) {
      let val = rawParams[params[p]].param_values[paramValues[params[p]]];
      let val_id = paramValues[params[p]];
      r = r.replace(p,val_id,true);
    }
    if(eval(r)){
      return true;
    }else{
      return false;
    }
  }
}
