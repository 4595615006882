import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input() systemName: string;
  @Input() title: string;
  @Input() button: string;
  @Input() from: string;
  @Input() brick: string = null;
  @Input() query: any = {};

  userId = null;
  user_name = null;
  subscription: Subscription;
  
  constructor(
    private router: Router,
    private modalService: NgbModal,
    // private modalService: ModalService,
    private userService: UserService,
    ) { 
      
      this.subscription = this.userService.user$.subscribe(data => {
        this.userId = data.id;
        this.user_name  = data.name;
        // console.log(data)
      });
    }

  ngOnInit(): void {
    if(this.userService.isUserSignedIn()){
      this.userId =this.userService.getUserId()
      this.user_name  = this.userService.getUserName();
    }
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  
  goBack() {
    this.router.navigate([this.button],{queryParams:this.query});
  }

  
  userModal(content) {
    // this.modalService.open
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {
    });

  }

  singOut() {
    this.userService.signOut();
    this.router.navigate(['/']);
  }
}
