import { Injectable, OnInit } from '@angular/core';
import { ApiService } from './api.service';
import { User } from '../_models/user.model';
import { Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class UserService {


  private _user: User = this.getEmptyUser();
  private _userSource: Subject<User> = new Subject();
  user$ = this._userSource.asObservable();

  userCookie = null;
  constructor(
    private cookieService: CookieService,
    private apiService: ApiService
    ) {
      if (cookieService.check('user')) {
        this.userCookie = this.cookieService.get('user');
        // console.log(this.userCookie)
        this.user = JSON.parse(this.userCookie);

      }
  }

  get user(): User {
    return this._user;
  }

  set user(data: User) {
    this._user = data;
  }

  get userSource(): Subject<User> {
    return this._userSource;
  }
  set userSource(data: Subject<User>) {
    this._userSource = data;
  }

  signIn(login, pass) {
    return new Promise((resolve, reject) => {
      this.apiService.getUser(login, pass).subscribe((data: any) => {

        if (data) {
          this.setUser(data);
          this.cookieService.set( 'user', JSON.stringify(this.user) );
          document.cookie = " user="+JSON.stringify(this.user)+"; "
        }
        resolve(data);
      });
    });
  }

  signOut() {
    this.cookieService.delete('user');
    this.userSource.next(this.newUser());
  }

  newUser() {
    this.user = this.getEmptyUser();
    return this.user;
  }

  setUser(userData) {
    // console.log(userData)
    this.user = {
      id: userData.s_id,
      name: userData.s_name,
      codename: userData.s_codename,
      login: userData.s_login,
      type: userData.s_type,
      email: userData.s_email,
      phone: userData.s_phone,
      address: {
          address: userData.sa_address,
          postcode: userData.sa_postcode,
          city: userData.sa_city
      }
      // email:userData.email,
      // phone:userData.telefon
    };
    this.userSource.next(this.user);
  }

  isUserSignedIn(): boolean {
    // console.log(this.user)
    if (this.user.id !== null) {
      return true;
    } else {
      return false;
    }
  }

  getUserType() {
    if (this.user.id !== null) {
      return this.user.type;
    } else {
      return null;
    }
  }
  setUserId(id) {
    this.user.id = id;
  }
  getUserId() {
    return this.user.id;
  }
  setUserName(name) {
    this.user.name = name;
  }

  getUserName() {
    return this.user.name
  }
  // setUserSurname(surname){
  //   this.user.surname=surname
  // }


  setUserLogin(login) {
    this.user.login = login;
  }

  getEmptyUser(): User {
    const user: User = {
      id: null,
      name: null,
      codename: null,
      login: null,
      type: null,
      email: null,
      phone: null,
      address: {
          address: null,
          postcode: null,
          city: null
      }
    };
    return user;
  }
}
