import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DiscountCodesService {
  
  private _discountCode : string;  
  private _discountValue : number;

  public get discountValue() : number {
    return this._discountValue;
  }
  public set discountValue(v : number) {
    this._discountValue = v;
  }
  
  public get discountCode() : string {
    return this._discountCode;
  }
  public set discountCode(v : string) {
    this._discountCode = v;
  }
  
  constructor(
    private apiService: ApiService
    ) { }

  clear(){
    this.discountValue = null;
    this.discountCode = null;
  }

  getCode(code) {
    return new Promise((resolve, reject) => {
      this.apiService.getDiscountCode(code).subscribe((data: any) => {
        if(data) {
          this.discountValue = data.dc_value;
          this.discountCode = data.dc_code;
        } else {
          this.discountValue = null;
          this.discountCode = null;
        }
        resolve(true);
      });
    });
  }
}
